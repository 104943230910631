import React, { Fragment } from 'react'
import config from 'Config'

const PreloadImages = () => {
  const { navPanelCategoryData } = config  
  if (!navPanelCategoryData) return null
  let images = []
  Object.keys(navPanelCategoryData).forEach((category, index) => {
    navPanelCategoryData[category].children.forEach(c => images.push(c.image))
  })

  return (
    <Fragment>
      {images.map((image, index) => (
        <link key={`preload-${index}`} rel="preload" href={image} as="image" />
      ))}
    </Fragment>
  )
}

export default PreloadImages
