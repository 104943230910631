import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Component from './component'
import { toggleNavPanel } from 'Actions'
import { skin } from 'Utils'
import skins from './skins'

const mapStateToProps = (state, props) => ({
  isActive: state.UI.navPanel,
  queryString: state.UI.location.search,
  showButtonOnFilters: state.UI.navPanel,
  screenType: state.UI.screenType
})

const mapDispatchToProps = dispatch => ({
  toggleNavPanel: bindActionCreators(toggleNavPanel, dispatch)
})

export default skin(
  connect(mapStateToProps, mapDispatchToProps)(Component),
  skins
)
