import { css } from '@emotion/core'
import { smallOnly, mediumUp } from 'Utils'

export default ({ theme, theme: { colors, mixins, spacing } }) => {
  return css`
    width: 100%;

    @media ${smallOnly} {
      ul {
        padding-top: ${spacing.vertical.xsmall};
      }

      li {
        @media ${smallOnly} {
          ${mixins.filterPanelListItem(theme)};
          border-bottom: 1px solid ${colors.mobileBorderLine};
          padding-left: ${spacing.vertical.xsmall};
        }
      }
      .selected {
        background-color: black;
        color: white;
        svg {
          fill: white;
        }
      }
    }

    @media ${mediumUp} {
      li {
        cursor: pointer;
        ${mixins.filterLinks(theme)}
      }

      .selected {
        background-color: black;
        color: white;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .close-icon {
        ${mixins.closeIcon(theme)}
      }
    }
  `
}
