import { css } from '@emotion/core'
import { smallOnly, mediumOnly, mediumUp, largeUp } from 'Utils'
import theme from 'Theme'

const { mixins, typography, spacing, colors } = theme

export default () => {
  return css`
    background-color: ${colors.bodyBackground};
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    border: 1px solid ${colors.borderGrey};

    @media ${mediumUp} {
      &:hover {
        ${mixins.cardBoxShadow(theme)}
      }
    }

    /*
    reserve space for the image
    avoid layout shifting before the iage has loaded
    */
    .content-card__image-wrapper {
      img {
        object-fit: cover;
      }

      @media ${smallOnly} {
        height: 300px;
        img {
          width: 100%;
          height: 300px;
          object-fit: cover;
        }
      }

      @media ${mediumOnly} {
        height: auto;
        img {
          height: auto;
          min-height: 200px;
        }
      }

      @media ${largeUp} {
        height: 330px;
        overflow: auto;
        img {
          height: 330px;
        }
      }
    }

    .content-card__info {
      padding: 14px 14px;
      display: flex;
      flex-direction: column;
      flex: 1;
      min-height: 80px;
    }

    .content-card__title {
      font-size: ${typography.baseFontSize};
      font-weight: bold;
      line-height: 1.3;
      word-break: break-word;
      margin-bottom: 10px;
    }

    .content-card__category {
      ${mixins.subText(theme)}
      margin-bottom: ${spacing.base_2}px;
    }
  `
}
