import { css } from '@emotion/core'
import { mediumOnly, mediumUp, smallOnly, largeUp } from 'Utils'
import theme from 'Theme'
const { mixins, colors, typography, spacing } = theme

const { mainContainerMaxWidth } = mixins

const plain = () => css`
  &.slider {
    ${mainContainerMaxWidth(theme)}
  }

  &.slider__instalook {
    .slider-header,
    .slider-cta {
      color: white;
      border-color: white;
    }
    .alice-carousel__dots-item {
      background-color: white;
    }

    .alice-carousel__dots-item:hover,
    .alice-carousel__dots-item.__active {
      background-color: ${colors.carousel.dots.color};
    }
  }

  .slider-header {
    display: flex;
    padding: ${spacing.base_3}px 0;
    position: relative;
    align-items: center;
  }

  .slider-title {
    font-size: ${typography.sizes.large};
    margin-right: ${spacing.base_3}px;
    @media ${smallOnly} {
      font-size: ${typography.sizes.larger};
    }
  }

  .slider-cta {
    @media ${smallOnly} {
      position: absolute;
      right: 0;
    }
    position: relative;
    font-size: ${typography.sizes.small};
    display: inline-block;
    line-height: 1.3;
    border-bottom: 2px solid ${colors.body};
    &:hover {
      border-color: ${colors.borderGrey};
    }
  }

  .alice-carousel {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    margin: auto;
    direction: ltr;

    .content-card,
    .category-panel {
      display: block;
      margin-left: 5px;
      margin-right: 5px;
    }
  }

  .alice-carousel__wrapper {
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
    width: 100%;
    height: auto;
  }

  .alice-carousel__stage {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    margin: 0;
    padding: 0;
    white-space: nowrap;
  }

  .alice-carousel__stage-item {
    position: relative;
    display: inline-block;
    width: 100%;
    vertical-align: top;
    white-space: normal;
    line-height: 0;

    @media ${mediumOnly} {
      &:nth-of-type(2n) {
        .content-card {
          margin-left: 0;
        }
      }
      &:nth-of-type(odd) {
        .category-panel,
        .content-card {
          margin-right: 10px;
        }
      }
      &:nth-of-type(even) {
        .category-panel,
        .content-card {
          margin-left: 10px;
        }
      }
    }
  }

  .alice-carousel__stage-item * {
    line-height: initial;
  }

  .alice-carousel__stage-item.__cloned {
    visibility: hidden;
    opacity: 0;
  }

  .alice-carousel__prev-btn,
  .alice-carousel__next-btn {
    display: inline-block;
    box-sizing: border-box;
    width: 50%;
    padding: 15px 10px;
  }

  .alice-carousel__prev-btn [data-area]::after,
  .alice-carousel__next-btn [data-area]::after {
    position: relative;
    content: attr(data-area);
    text-transform: capitalize;
  }

  .alice-carousel__prev-btn {
    text-align: right;
  }

  .alice-carousel__prev-btn-item,
  .alice-carousel__next-btn-item {
    display: inline-block;
    cursor: pointer;
    color: #465798;
  }
  .alice-carousel__prev-btn-item:hover,
  .alice-carousel__next-btn-item:hover {
    color: darkred;
  }
  .alice-carousel__prev-btn-item.__inactive,
  .alice-carousel__next-btn-item.__inactive {
    opacity: 0.4;
  }

  .alice-carousel__play-btn {
    position: absolute;
    top: 30px;
    left: 20px;
    display: inline-block;
  }
  .alice-carousel__play-btn:hover {
    cursor: pointer;
  }
  .alice-carousel__play-btn-wrapper {
    position: relative;
    width: 32px;
    height: 32px;
    padding: 10px;
    border-radius: 50%;
    background-color: #fff;
  }

  .alice-carousel__play-btn-item {
    position: absolute;
    width: 32px;
    height: 32px;
    cursor: pointer;
    border: 0;
    outline: none;
    background: transparent;
  }
  .alice-carousel__play-btn-item::before,
  .alice-carousel__play-btn-item::after {
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    content: '';
    transition: all 0.3s linear;
    border-width: 8px 0 8px 15px;
    border-style: solid;
    border-color: transparent;
    border-left-color: #465798;
  }
  .alice-carousel__play-btn-item::before {
    left: 5px;
    height: 14px;
  }
  .alice-carousel__play-btn-item::after {
    top: 7px;
    left: 18px;
  }
  .alice-carousel__play-btn-item.__pause::before,
  .alice-carousel__play-btn-item.__pause::after {
    height: 30px;
    border-width: 0 0 0 10px;
  }
  .alice-carousel__play-btn-item.__pause::after {
    top: 0;
    left: 18px;
  }

  .alice-carousel__dots {
    margin: 0px 0px 0px 20px;
    padding: 20px 20px;
    list-style: none;
    text-align: center;
  }

  .alice-carousel__dots-item {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-right: 20px;
    cursor: pointer;
    border-radius: 50%;
    background-color: ${colors.carousel.dots.color};
  }

  .alice-carousel__dots-item:hover,
  .alice-carousel__dots-item.__active {
    background-color: ${colors.carousel.dots.active};
  }

  .alice-carousel__slide-info {
    position: absolute;
    top: 20px;
    right: 20px;
    display: inline-block;
    padding: 5px 10px;
    color: #465798;
    border-radius: 5px;
    background-color: rgba(224, 228, 251, 0.6);
  }
  .alice-carousel__slide-info-item {
    vertical-align: middle;
    line-height: 0;
  }

  .alice-carousel .animated {
    animation-fill-mode: both;
  }

  .alice-carousel .animated-out {
    z-index: 1;
  }

  .alice-carousel .fadeOut {
    animation-name: fadeOut;
  }

  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      visibility: hidden;
    }
  }
`
export default plain
