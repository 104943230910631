import { css } from '@emotion/core'
import trendVariant from './trendVariant'
import navPanelVariant from './msnNavPanelVariant'
import instalookVariant from './instalookVariant'
import hubVariant from './hubVariant'
import smallArticleVariant from './smallArticleVariant'
import theme from 'Theme'
import { mediumUp } from 'Utils'

const { spacing, colors, typography, mixins } = theme

const defaultSkin = () => css`
  background-color: ${colors.bodyBackground};
  border: 1px solid ${colors.borderGrey};
  position: relative;
  display: flex;
  flex-direction: column;

  @media ${mediumUp} {
    &:hover {
      ${mixins.cardBoxShadow(theme)}
    }
  }

  .content-card__image-wrapper {
    img {
      object-fit: cover;
      height: 300px;
      width: 100%;
    }
  }

  .content-card__info {
    padding: ${spacing.base_2}px;
    position: relative;
  }

  .content-card__title {
    font-size: ${typography.baseFontSize};
    font-weight: bold;
    word-break: break-word;
    line-height: 1.3;
  }

  .content-card__category {
    width: calc(100% - ${spacing.base_2 * 2}px);
    ${mixins.subText(theme)};
    line-height: 1em;
    margin-bottom: ${spacing.base_2}px;
  }
`

export default props => {
  if (props.variant === 'Trend') {
    return trendVariant()
  } else if (props.variant === 'navPanel') {
    return navPanelVariant()
  } else if (props.variant === 'Instalooks') {
    return instalookVariant(props)
  } else if (props.variant === 'smallArticle') {
    return smallArticleVariant()
  } else if (props.variant === 'hub') {
    return hubVariant()
  } else {
    return defaultSkin(props)
  }
}
