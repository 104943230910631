import {
  ApolloClient,
  createHttpLink,
  ApolloLink,
  InMemoryCache
} from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { setContext } from '@apollo/client/link/context'
import fetch from 'node-fetch'
import config from 'Config'
import {
  PRODUCTS_QUERY_ARGS,
  PRODUCTS_QUERY_SORT,
  CONTENTS_QUERY_ARGS
} from 'Constants'

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message, location, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${location}, Path: ${path}`
      )
    )
  }
  if (networkError) console.log(`[Network error]: ${networkError}`)
})

const { NODE_ENV } = process.env

const uri = `${process.env.ANT_PROTOCOL}://${process.env.ANT_DOMAIN}:${
  process.env.ANT_ENVIRONMENT === 'local' ? process.env.ANT_PORT : ''
}${config.shopPath}/graphql`

const serverLinks = [
  errorLink,
  createHttpLink({
    fetch,
    uri
  })
]

const { useLoadMoreUI, useArticleMoreUI } = config

const typePolicies = {
  ProductStore: {
    keyFields: ['name']
  },
  Product: {
    keyFields: ['uuid', 'title']
  },
  Content: {
    keyFields: ['uuid'],
    fields: {
      layout: {
        merge(existing, incoming, { mergeObjects }) {
          return mergeObjects(existing, incoming)
        }
      },
      categories: {
        merge(existing = [], incoming) {
          return [...existing, ...incoming]
        }
      }
    }
  },
  Query: {
    fields: {
      products: {
        keyArgs: args => {
          // console.log('products args: ', args)
          let keyArguments = []
          PRODUCTS_QUERY_ARGS.forEach(param => {
            if (args.arguments[param]) {
              keyArguments.push(param)
            }
          })

          let keySort = []
          const keyName = 'sort'
          const argsSortKeys = Object.keys(args[keyName])

          PRODUCTS_QUERY_SORT.forEach(param => {
            if (argsSortKeys.includes(param)) {
              keySort.push(param)
            }
          })

          let keyPagination = []
          if (!useLoadMoreUI) {
            // paginate only
            keyPagination.push('page')
            keyPagination.push('size')
          }
          // console.log('products keyPagination: ', keyPagination)
          // console.log('products keyArguments: ', keyArguments)
          // console.log('products keySort: ', keySort)
          return [
            'arguments',
            keyArguments,
            'sort',
            keySort,
            'pagination',
            keyPagination
          ]
        },
        merge(
          existing = {
            products: []
          },
          incoming,
          { mergeObjects }
        ) {
          if (!incoming.products || incoming.products.length < 0) {
            return existing
          }
          const merged = mergeObjects(existing, incoming)
          const mergedProducts = [...existing.products, ...incoming.products]
          merged.products = mergedProducts
          return merged
        }
      },
      contents: {
        keyArgs: args => {
          // console.log('contents args: ', args)
          let keyArguments = ['type', 'state']

          CONTENTS_QUERY_ARGS.forEach(param => {
            if (args.arguments[param]) {
              keyArguments.push(param)
            }
          })
          let keyPagination = []
          if (args.pagination.size) {
            keyPagination.push('size')
          }
          if (!useArticleMoreUI) {
            // paginate only
            keyPagination.push('page')
          }
          // console.log('contents keyPagination: ', keyPagination)
          // console.log('contents keyArguments: ', keyArguments)

          return ['arguments', keyArguments, 'pagination', keyPagination]
        },
        merge(
          existing = {
            contents: []
          },
          incoming
        ) {
          if (!incoming.contents || incoming.contents.length < 0) {
            return existing
          }
          const mergedContents = [...existing.contents, ...incoming.contents]
          const merged = { ...existing, ...incoming }
          merged.contents = mergedContents

          return merged
        }
      }
    }
  }
}

const createApolloServerClient = () =>
  new ApolloClient({
    ssrMode: NODE_ENV === 'production',
    link: ApolloLink.from(serverLinks),
    cache: new InMemoryCache({
      resultCaching: false,
      typePolicies
    })
  })

// const delay = setContext(
//   request =>
//     new Promise((resolve, reject) => {
//       setTimeout(() => {
//         resolve()
//       }, 8000)
//     })
// )

const clientLinks = [
  // delay,
  errorLink,
  createHttpLink({
    fetch,
    uri,
    includeExtensions: true
  })
]

const createApolloClientClient = () =>
  new ApolloClient({
    ssrForceFetchDelay: 100,
    link: ApolloLink.from(clientLinks),
    connectToDevTools: true,
    cache: new InMemoryCache({
      resultCaching: false,
      typePolicies
    }).restore(window.__APOLLO_STATE__)
  })

export { createApolloServerClient, createApolloClientClient }
