import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { path, sendTrackingEvent } from 'Utils'
import { Link } from 'Blocks'
import config, { gaSettings } from 'Config'
import { NAV_PANEL_CONTENTS, PAGE_CONTEXT as p } from 'Constants'

const { menu } = gaSettings

class CategoryLink extends Component {
  static propTypes = {
    activeTopLevelCategory: PropTypes.oneOfType([
      PropTypes.shape({
        name: PropTypes.string,
        uuid: PropTypes.string
      }),
      PropTypes.string
    ]),
    category: PropTypes.shape({
      name: PropTypes.string,
      slug: PropTypes.string,
      uuid: PropTypes.string
    }),
    closeNavPanel: PropTypes.func,
    currentPath: PropTypes.array,
    isTouch: PropTypes.any,
    navPanel: PropTypes.string,
    openNavPanel: PropTypes.func,
    screenType: PropTypes.string,
    setActiveContentType: PropTypes.func,
    setActiveTopLevelCategory: PropTypes.func,
    setCurrentCategory: PropTypes.func,
    setCurrentPath: PropTypes.func,
    validTree: PropTypes.any
  }

  componentDidMount() {
    this.mouseIsOver = false
  }

  handleMouseEnter = category => {
    const { uuid, name } = category
    // delay opening the menu to confirm intention and allow mousing to the header and SearchBar
    this.mouseIsOver = true

    const delay = this.props.navPanel !== 'menu' ? 300 : 0
    this.delayOpenNav = setTimeout(() => {
      if (this.mouseIsOver) {
        this.props.openNavPanel(NAV_PANEL_CONTENTS.MENU)
        this.props.setActiveTopLevelCategory({ ...{ uuid, name } })
        this.props.setActiveContentType('')
      }
    }, delay)
  }

  handleMouseLeave = () => {
    this.mouseIsOver = false
  }

  handleMobileClick = category => {
    const { uuid, name } = category
    if (
      uuid === this.props.activeTopLevelCategory.uuid &&
      this.props.navPanel === 'menu'
    ) {
      this.props.closeNavPanel()
    } else {
      this.props.openNavPanel(NAV_PANEL_CONTENTS.MENU)
      this.props.setActiveTopLevelCategory({ ...{ uuid, name } })
    }
  }

  handleLinkClick = category => {
    const { uuid, name } = category
    if (
      uuid === this.props.activeTopLevelCategory.uuid &&
      this.props.navPanel !== 'menu'
    ) {
      this.props.openNavPanel(NAV_PANEL_CONTENTS.MENU)
      this.props.setActiveTopLevelCategory({ ...{ uuid, name } })
    } else {
      this.props.setCurrentCategory(uuid)
      this.props.setCurrentPath([process.env.ANT_BROWSE_ID, uuid])
      this.props.closeNavPanel()
      clearTimeout(this.delayOpenNav)
    }
  }

  render() {
    const { category } = this.props
    const { uuid, slug, name } = category
    const url = path(p.PRODUCT_LIST, {
      pageType: 'products',
      uuid,
      subCategory: slug
    })
    const currentClass = this.props.currentPath.includes(uuid) ? 'current' : ''
    const activeClass =
      this.props.activeTopLevelCategory.uuid === uuid &&
      this.props.navPanel === 'menu'
        ? 'active'
        : ''

    const gaEvent = {
      category: menu.category,
      action: menu.action,
      label: `linkName=${name} - destinationUrl=${url}`
    }

    const additionalProps = {
      linkName: name,
      destinationUrl: url,
    }

    const renderMainMenuItem = () => {
      return this.props.screenType === 'small' ? (
        <span
          className={`item ${activeClass} ${currentClass}`}
          key={`panel-${category.uuid}`}
          onClick={() => {
            this.handleMobileClick(category)
          }}
        >
          {category.name}
        </span>
      ) : this.props.isTouch ? (
        <span
          className={`item ${activeClass} ${currentClass}`}
          key={`panel-${category.uuid}`}
          onMouseEnter={() => {
            this.handleMouseEnter(category)
          }}
          onMouseLeave={this.handleMouseLeave}
        >
          {category.name}
        </span>
      ) : (
        <Link
          to={url}
          className={`item ${activeClass} ${currentClass}`}
          key={`panel-${category.uuid}`}
          onMouseEnter={() => {
            this.handleMouseEnter(category)
          }}
          onMouseLeave={this.handleMouseLeave}
          onClick={() => {
            sendTrackingEvent({ amplitude: {}, gaEvent, additionalProps })
            this.handleLinkClick(category)
          }}
          aria-label={category.name}
          alt={category.name}
        >
          {category.name}
        </Link>
      )
    }

    return config.useMainMenu && this.props.validTree ? (
      renderMainMenuItem()
    ) : (
      <Link
        onClick={() => sendTrackingEvent({ amplitude: {}, gaEvent, additionalProps })}
        className="item"
        key={uuid}
        to={url}
        aria-label={name}
        alt={name}
      >
        {name}
      </Link>
    )
  }
}

export default CategoryLink
