export const colors = {
  bodyBackground: '#FFF',
  trendBackground: '#f9f9f9',
  body: '#000',
  active: '#000',
  primary: '#000',
  secondary: '#505151',
  tertiary: '#c3c3c3',
  discount: '#000',
  discountedPrice: '#979797',
  borderGrey: '#D8D8D8',
  boxShadow: '#EBEBEB',
  darkGrey: '#979797',
  lightGrey: '#ccc',
  cardGrey: '#F5F5F5',
  alt1: '#42b7f8',
  disabled: '#9a9a9a',
  buttonBackground: '#EBEBEB',
  overlayBackground: 'rgba(0, 0, 0, .8)',
  complementary: {
    primary: '#FFF',
    secondary: '#666',
    alt1: '#205978'
  },
  toolbar: {
    buttonColor: '#CCC',
    textColor: '#FFF',
    backgroundColor: '#205978',
    backgroundColorDark: '#0F2A39'
  },
  article: {
    delete: 'red',
    status: {
      DRAFT: 'grey',
      AWAITING_REVIEW: 'orange',
      PUBLISHED: 'green'
    },
    item: {
      title: '#000'
    }
  },
  mainNav: {
    active: 'rgba(0, 0, 0, 0.4)',
    current: '#FFF',
    complementary: {
      active: '#FFF',
      current: '#000'
    }
  },
  button: {
    disabled: '#9a9a9a'
  }
}

export default colors
