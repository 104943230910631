const zIndex = {
  panelOverlay: 2,
  panelContent: 3,
  backdrop: 4,
  dialogContent: 5,
  dialog: 1001,
  toTopButton: 6,
  searchBox: 1,
  filterPanel: {
    panel: 1,
    menu: 201,
    activePanel: 99999,
    closeDialog: 4,
    loading: 200
  },
  navPanel: 2,
  navPanelOverlay: 1,
  siteWide: 4
}

export default zIndex
