import spacing from './spacing'

export const layout = {
  maxWidth: {
    mainContainer: 1300,
    page: 1300,
    article: 735,
    productOverview: 960,
    trendImage: 1024,
    card: 600
  },
  gutterSize: {
    small: spacing.base_1,
    medium: spacing.base_3,
    large: spacing.base_3,
    fallback: spacing.base_3,
    home: {
      small: spacing.base_3,
      large: spacing.base_3,
      fallback: spacing.base_3
    },
    homeSmall: {
      small: spacing.base_2,
      large: spacing.base_3,
      fallback: spacing.base_3
    }
  },
  itemsPerRow: {
    altProduct: { small: 2, fallback: 4 },
    productList: { small: 2, medium: 3, large: 4, fallback: 4 },
    stores: { small: 2, medium: 4, fallback: 4 },
    homeInstalooks: { small: 1, medium: 2, fallback: 4 },
    default: { small: 1, medium: 2, fallback: 4 }
  },
  limits: {
    homeArticles: { small: 4, medium: 3, large: 4, fallback: 4 },
    homeTrends: { small: 4, medium: 3, large: 4, fallback: 4 },
    fallback: { medium: 3, fallback: 4 }
  },
  navPanel: {
    height: 443
  }
}

export default layout
