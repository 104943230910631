import { css } from '@emotion/core'
// import { mediumUp } from 'Utils'
import theme from 'Theme'

const {
  colors,
  typography,
  mixins,
  components: { articleCard }
} = theme

export default () => {
  return css`
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border: 1px solid ${colors.borderGrey};
    background-color: ${colors.white};

    &:hover {
      ${mixins.cardBoxShadow(theme)}
    }

    .content-card__image-wrapper {
      position: relative;
      width: 50%;

      img {
        object-fit: cover;
        height: 165px;
        width: 100%;
      }
    }

    .content-card__info {
      padding: 16px;
      display: flex;
      flex-direction: column;
      flex: 1;
      justify-content: space-between;
      min-height: 144px;
    }

    .content-card__title {
      font-weight: ${articleCard.fontWeight};
      font-size: ${typography.sizes.msmall};
      margin: 0 0;
      word-break: break-word;
    }

    .content-card__category {
      ${mixins.subText(theme)}
      font-size: ${typography.sizes.msmall};
    }
  `
}
