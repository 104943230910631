import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import component from './component'
import { toggleNavPanel } from 'Actions'

const mapStateToProps = state => {
  return {
    isActive: state.UI.navPanel
  }
}
const mapDispatchToProps = dispatch => ({
  toggleNavPanel: bindActionCreators(toggleNavPanel, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(component)
