import { css } from '@emotion/core'
import { smallOnly, mediumUp } from 'Utils'
import theme from 'Theme'

const { typography, spacing, mixins } = theme

const { sectionHeading, cardBoxShadow } = mixins

export default () => css`
  background-color: white;
  display: block;

  @media ${smallOnly} {
    .category-panel__image {
      height: 350px;
      object-fit: cover;
      width: 100%;
    }
  }

  @media ${mediumUp} {
    &:hover {
      ${cardBoxShadow(theme)}
    }
  }

  .category-panel__title {
    ${sectionHeading(theme)}
    font-size: ${typography.baseFontSize};

    @media ${mediumUp} {
      margin-bottom: ${spacing.vertical.small};
      font-size: ${typography.baseFontSize};
    }
  }

  .category-panel__content {
    padding: 16px;
    text-align: center;

    @media ${mediumUp} {
      min-height: 130px;
    }
  }
`
