import { gql } from '@apollo/client'
import { smallContentFragment } from 'Fragments'
import { getPage } from 'Utils'
import config from 'Config'

const GET_CONTENTS = /* GraphQL */ gql`
  query contents($arguments: GetContents!, $pagination: Pagination!) {
    contents(arguments: $arguments, pagination: $pagination) {
      _cache
      contents {
        ...smallContent
      }
      aggregations {
        count
        themes {
          name
          count
        }
        celebrities {
          name
          count
        }
      }
      pagination {
        next
        pages
        previous
      }
    }
  }

  ${smallContentFragment}
`

export const getArticlesQueryVariables = (
  queryString,
  size,
  type,
  isFeatured
) => {
  const query = {
    arguments: {
      type,
      state: 'PUBLISHED'
    },
    pagination: {
      page: getPage(queryString),
      size: size || config.pagination.articleList
    }
  }

  if (isFeatured) {
    query.arguments.isFeatured = true
  }
  return query
}

export const getArticlesCustomQueryVariables = (
  queryString,
  size,
  type,
  isFeatured,
  theme,
  uuids,
  negateuuids
) => {
  const query = {
    arguments: {
      type,
      state: 'PUBLISHED',
      uuids,
      negateuuids: negateuuids || 'no',
      theme: theme || ''
    },
    pagination: {
      page: getPage(queryString),
      size: size || config.pagination.articleList
    }
  }

  if (isFeatured) {
    query.arguments.isFeatured = true
  }
  return query
}

export default GET_CONTENTS
