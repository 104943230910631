import base from './base'
import merge from 'lodash/merge'
import { navPanelCategoryData } from './navPanelCategoryData/octer'
import { PAGE_CONTEXT as p } from 'Constants'

export default merge(base, {
  name: 'octer',
  group: 'plain',
  useMainMenu: true,
  favouritesEnabled: true,
  preloadCategoryImages: true,
  apiKey: 'VGDliwxFpvN5tz8pfotOPg2OyZSuc4bl8Qyv1Fv73rS6HMKc',
  useEmbedDemo: true,
  interStitialEnabled: true,
  altClickrefEnabled: true,
  articlesEnabled: true,
  trendsEnabled: true,
  instalooksEnabled: true,
  hubsEnabled: false,
  useBreadcrumbs: false,
  compareCategoryLinksEnabled: true,
  articlesOnProductListPage: true,
  showStoreNameOnProductCard: true,
  robotsText: false,
  useScrollButton: true,
  hubEnabled: true,
  userMaven: {
    enabled: true
  },
  siteWide: {
    searchPlaceholderText: 'Search for items',
    useExtraLinks: true,
    extraLinks: [
      {
        to: 'ARTICLE_LIST',
        label: 'Stories',
        children: '/article/',
        type: p.ARTICLE
      },
      {
        to: 'TREND_LIST',
        label: 'Trends',
        children: '/trend/',
        type: p.TREND
      },
      {
        to: 'INSTALOOK_LIST',
        label: 'Celebrity',
        children: '/instalook/',
        type: p.INSTALOOK
      }
    ]
  },
  contentListSkins: {
    homeInstalooks: 'Instalooks',
    instalook: 'Instalooks',
    instalookList: 'Instalooks',
    homeTrends: 'Trend',
    trendList: 'Trend',
    smallArticle: 'smallArticle',
    hubTrend: 'Trend',
    productInstalooks: 'Instalooks'
  },
  productList: {
    slots: {
      useContentListItem: true
    }
  },
  articles: {
    relatedLabel: 'Latest Articles',
    listHeading: 'Style Journal',
    listSubHeading:
      'Everything you need to know about fashion and lifestyle now.',
    homeArticles: {
      title: 'Latest Style Stories',
      intro: 'Everything you need to know about fashion and lifestyle now',
      limit: 12,
      showMore: 'View all'
    },
    article: {
      title: 'Style Journal',
      intro: 'Everything you need to know about fashion and lifestyle now',
      limit: 4,
      showMore: 'View all'
    },
    productPage: {
      title: 'Style Journal',
      intro: 'Everything you need to know about fashion and lifestyle now',
      limit: 6,
      showMore: 'View all'
    }
  },
  trends: {
    homeTrends: {
      title: 'Latest Trends'
    },
    productPage: {
      title: 'Shop The Latest Trends',
      intro: 'Discover even more of the latest trends for both men and women.',
      limit: 6,
      showMore: 'View all'
    },
    main: {
      title: 'Style Journal',
      intro: 'Everything you need to know about fashion and lifestyle now',
      withSub: false,
      withBtnIcon: false,
      withCta: true,
      showMore: 'View all',
      limit: 4
    },
    trend: {
      title: 'Shop The Latest Trends',
      intro: 'Discover even more of the latest trends for both men and women.',
      withIntro: true,
      img: '',
      withBtnIcon: false,
      showMore: 'View all',
      limit: 5
    },
    secondary: {
      title: 'placeholder',
      withSub: false,
      intro: 'placeholder'
    },
    listHeading: 'Trends',
    listSubHeading:
      'Get your fashion fix with easy-to-shop trends for both men and women.',
    defaultTrendCategory: 'fashion'
  },
  instalooks: {
    homeInstalooks: {
      title: 'Celebrity Get The Look'
    },
    instalook: {
      limit: 5
    },
    productPage: {
      title: 'Shop by Celebrity',
      intro:
        'See it. Like it. Shop it. Discover the latest celebrity-inspired looks, as seen on the ’gram.',
      limit: 6,
      showMore: 'View all'
    }
  },
  article: {
    showTaggedCategory: true,
    showSponsoredBy: true,
    item: {
      image: {
        showExtraInfo: true
      }
    }
  },
  sorting: {
    lowLabel: 'Low to high',
    highLabel: 'High to low'
  },
  helmet: {
    partner: 'Octer',
    defaultDescription:
      'Octer is a marketplace of the biggest UK stores where you can shop departments or shop by store. From fashion to gadgets we instantly compare thousands of brands and show you the nearest matches; so you will always get the perfect style and price and discover products from stores you hadn’t considered before.',
    articles: {
      title: 'Latest articles',
      description:
        "View all the latest Shopping Articles from Octer to see what's trending"
    }
  },
  home: {
    panels: [
      {
        uuid: '22b3cf9f84e0',
        name: 'Womens',
        slug: 'womens',
        description:
          'Your new season wardrobe awaits with the latest new arrivals.',
        image: {
          small:
            'https://ik.imagekit.io/sqhmihmlh/octer/kinga-cichewicz-3McEVKL4-bg-unsplash.jpg',
          large:
            'https://ik.imagekit.io/sqhmihmlh/octer/kinga-cichewicz-3McEVKL4-bg-unsplash.jpg'
        }
      },
      {
        uuid: '0b74ea8ebaf4',
        name: 'Mens',
        slug: 'mens',
        description:
          'From high street to high end, discover the menswear to know now.',
        image: {
          small:
            'https://ik.imagekit.io/sqhmihmlh/octer/zahir-namane-TjUJJACTav4-unsplash.jpg',
          large:
            'https://ik.imagekit.io/sqhmihmlh/octer/zahir-namane-TjUJJACTav4-unsplash.jpg'
        }
      },
      {
        uuid: '7c8a153fed14',
        name: 'Homeware',
        slug: 'homeware',
        description:
          'Make a house a home with trending furnishings from big name brands.',
        image: {
          small:
            'https://ik.imagekit.io/sqhmihmlh/octer/allie-smith-qJXC-BlAWJY-unsplash.jpg',
          large:
            'https://ik.imagekit.io/sqhmihmlh/octer/allie-smith-qJXC-BlAWJY-unsplash.jpg'
        }
      },
      {
        uuid: 'd02afe9c8aaa',
        name: 'Beauty',
        slug: 'beauty',
        description:
          'Makeup must-haves & essential skincare buys to get you looking and feeling great.',
        image: {
          small:
            'https://ik.imagekit.io/sqhmihmlh/octer/stil-Vb3Xr4J_D4s-unsplash.jpg',
          large:
            'https://ik.imagekit.io/sqhmihmlh/octer/stil-Vb3Xr4J_D4s-unsplash.jpg'
        }
      }
    ],
    categories: {
      heading: 'Shop By Category'
    },
    useStoreTitle: true,
    storesTitle: 'Brands We Love',
    stores: [
      {
        name: 'Reiss',
        logo:
          'https://ik.imagekit.io/sqhmihmlh/hammerson/brands-we-love/tr:w-200/reiss.png',
        to:
          'https://www.awin1.com/cread.php?awinaffid=158752&awinmid=6985&clickref=homepagehkduym&platform=dl&p=%5B%5Bhttps%253A%252F%252Fwww.reiss.com%252F%5D%5D'
      },
      {
        name: 'HARVEY NICHOLS',
        logo:
          'https://ik.imagekit.io/sqhmihmlh/msn/homepage/stores/harvey-nichols.png',
        to:
          'https://click.linksynergy.com/deeplink?id=AqjbTxMPHY4&mid=39654&u1=homepagejnhlhg&murl=https%3A%2F%2Fwww.harveynichols.com%2F'
      },
      {
        name: 'Boden',
        logo:
          'https://ik.imagekit.io/sqhmihmlh/octer/homepage/stores/BODEN_MASTER_LOGO_ARTWORK_BLACK.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1671033693483',
        to:
          'https://www.awin1.com/cread.php?awinmid=1669&awinaffid=158752&clickref=qvhvmuhomepage&ued=https%3A%2F%2Fwww.boden.co.uk%2F'
      },
      {
        name: 'RIVER ISLAND',
        logo:
          'https://ik.imagekit.io/sqhmihmlh/octer/homepage/stores/ri2.png?updatedAt=1634119002662',
        to:
          'https://click.linksynergy.com/deeplink?id=AqjbTxMPHY4&mid=38432&u1=homepagejnhlhg&murl=https%3A%2F%2Fwww.riverisland.com%2Fwomen'
      }
    ]
  },
  filters: {
    sale: {
      checkbox: false
    },
    topLevelCategories: false,
    colors: {
      enabled: true
    }
  },
  buyButton: {
    label: 'Buy now',
    lowStockLabel: 'Low stock - buy now',
    useBuyButtonIcon: true
  },
  routes: {
    [p.TERMS]: {
      page: 'TermsAndConditionsPage',
      path: `${process.env.ANT_PREFIX}/TermsAndConditions`
    },
    [p.EMBED]: {
      page: 'EmbedPage',
      path: `${process.env.ANT_PREFIX}/embed/:uuid`
    },
    [p.INSTALOOK]: {
      page: 'InstalookPage',
      path: `${process.env.ANT_PREFIX}/instalook/:uuid/:slug`
    },
    [p.INSTALOOK_LIST]: {
      page: 'InstalookListPage',
      path: `${process.env.ANT_PREFIX}/instalooks`,
      exact: true
    }
  },
  compareLinkLabel: 'More matches',
  pagination: {
    articleList: 40,
    instalookList: 16,
    trendList: 25,
    productList: 32,
    altProducts: 4,
    compare: 23,
    productListArticles: 3,
    hubList: 3
  },
  relatedArticles: {
    articleCard: false
  },
  articleCard: {
    withExtraInfo: true,
    withSubTitle: true,
    image: {
      small: {
        width: '310',
        height: '400'
      },
      medium: {
        width: '430',
        height: '400'
      },
      large: {
        width: '310',
        height: '225'
      }
    }
  },
  trendCard: {
    image: {
      small: {
        width: '660',
        height: '600'
      },
      medium: {
        width: '460',
        height: '500'
      },
      large: {
        width: '310',
        height: '330'
      }
    }
  },
  instalookCard: {
    image: {
      small: {
        width: '660',
        height: '600'
      },
      medium: {
        width: '430',
        height: '400'
      },
      large: {
        width: '310',
        height: '225'
      }
    }
  },
  shopPath: process.env.ANT_PREFIX,
  compareTitle: 'You might also like...',
  descriptionLabel: 'Product Information',
  navPanelCategoryData
})
