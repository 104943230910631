import { css } from '@emotion/core'
import { mediumOnly, mediumUp, largeUp } from 'Utils'
import { PAGE_CONTEXT as p } from 'Constants'
import theme from 'Theme'

const { colors, spacing, typography, mixins } = theme

const panelContentHover = () => css`
  .content-card__image-wrapper {
    &::after {
      opacity: 1;
    }
  }
`

export default props => {
  const isNavPanel = props.pageName === 'nav-panel-content'
  const pageContextIsHome = props.pageContext === p.HOME_INSTALOOKS
  return css`
    background-color: ${colors.bodyBackground};
    position: relative;
    border: 1px solid ${colors.borderGrey};

    @media ${mediumUp} {
      height: ${isNavPanel ? '380px' : 'auto'};
      &:hover {
        ${isNavPanel || pageContextIsHome
          ? panelContentHover()
          : mixins.cardBoxShadow(theme)};
      }
    }

    @media ${largeUp} {
      height: ${isNavPanel ? '380px' : 'auto'};
      min-height: ${isNavPanel ? '380px' : '440px'};
    }

    .content-card__image-wrapper {
      margin: ${spacing.base_1}px;
      height: 330px;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;

      @media ${mediumOnly} {
        height: ${isNavPanel ? '200px' : '200px'};
      }
      @media ${largeUp} {
        height: ${isNavPanel ? '220px' : '300px'};
      }
      img {
        width: 100%;
        height: 330px;
        @media ${mediumOnly} {
          height: ${isNavPanel ? '200px' : '200px'};
          min-height: 200px;
        }
        @media ${largeUp} {
          height: ${isNavPanel ? '220px' : '300px'};
        }
        object-fit: cover;
      }

      &::after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.25);
        opacity: 0;
      }
    }

    .insta-card__info-wrapper {
      @media ${mediumOnly} {
        min-height: 110px;
      }
    }

    .insta-card__bar {
      border-top: 1px solid ${colors.borderGrey};
      border-bottom: 1px solid ${colors.borderGrey};
      display: flex;
      align-items: center;
      padding: ${spacing.base_1}px 0;
      svg {
        display: inline-block;
        height: 16px;
        width: 16px;
        stroke: ${colors.darkGrey};
        fill: ${colors.darkGrey};
        margin-left: ${spacing.base_1}px;
        &[name='insta-comment'] {
          stroke: ${colors.darkGrey};
          stroke-width: 1px;
        }
      }
    }
    .content-card__info {
      padding: ${spacing.base_2}px ${spacing.base_1}px 0 ${spacing.base_1}px;
      display: flex;
      flex-direction: column;
      flex: 1;
      justify-content: space-between;
    }

    .content-card__title {
      line-height: 1.3;
      font-size: ${typography.baseFontSize};
      margin: 0 0 ${spacing.base_2}px 0;
      word-break: break-word;
    }
  `
}
