import { spacing } from '../spacing'

export const articleTarget = {
  spacing: {
    small: spacing.base_3,
    large: spacing.base_3,
    paragraph: spacing.base_3
  }
}

export default articleTarget
