import get from 'lodash/get'
import config from 'Config'

const errorRedirect = e => {
  const statusCode = get(e, 'graphQLErrors[0].extensions.Redirect.Code', '500')
  // Assumes we only redirect on 410
  const redirectLocation = `${statusCode === 410 ? config.shopPath : ''}${get(
    e,
    'graphQLErrors[0].extensions.Redirect.Path}',
    ''
  )}`
  return { redirectLocation, statusCode }
}

export default errorRedirect
