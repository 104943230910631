import { css } from '@emotion/core'
import { smallOnly, mediumUp } from 'Utils'
import theme from 'Theme'

const { layout } = theme

export default () => {
  const {
    maxWidth: { page: maxPageWidth }
  } = layout
  return css``
}
