import React from 'react'
import PropTypes from 'prop-types'
import { Link, Icon } from 'Blocks'
import config from 'Config'

const Sorting = props => {
  const { lowTo, highTo, isLowSelected, isHighSelected, className } = props

  return (
    <div {...{ className }}>
      <div className="links filter-links">
        <Link className={isLowSelected ? 'selected' : ''} to={lowTo}>
          {config.sorting.lowLabel}
          {isLowSelected && <Icon className="close-icon" name="close" />}
        </Link>
        <Link className={isHighSelected ? 'selected' : ''} to={highTo}>
          {config.sorting.highLabel}
          {isHighSelected && <Icon className="close-icon" name="close" />}
        </Link>
      </div>
    </div>
  )
}

Sorting.defaultProps = {
  lowTo: '',
  highTo: '',
  isLowSelected: false,
  isHighSelected: false,
  className: ''
}

Sorting.propTypes = {
  className: PropTypes.string,
  highTo: PropTypes.string,
  isHighSelected: PropTypes.bool,
  isLowSelected: PropTypes.bool,
  lowTo: PropTypes.string
}

export default Sorting
