import PropTypes from 'prop-types'
import React from 'react'
import config from 'Config'
import { ContentListSimple, ListSlider, CategoryPanel } from 'Blocks'
import { PAGE_CONTEXT as p, SCREEN_TYPE } from 'Constants'
import { path } from 'Utils'

const {
  home: { panels }
} = config
const heading = config.home.categories.heading

const homePanels = panels.filter(
  panel =>
    panel.name === 'Womens' ||
    panel.name === 'Browse' ||
    panel.name === 'Mens' ||
    panel.name === 'Beauty' ||
    panel.name === 'Homeware' ||
    panel.name === 'Damen' ||
    panel.name === 'Herren' ||
    panel.name === 'Haus & Garten' ||
    panel.name === 'Sport'
)

const browseLink = path(p.PRODUCT_LIST, {
  uuid: process.env.ANT_BROWSE_ID,
  subCategory: 'browse'
})

const ListCategories = props => {
  return props.screenType === SCREEN_TYPE.SMALL ||
    props.screenType === SCREEN_TYPE.MEDIUM ? (
    <ListSlider
      variant="categories"
      header={config.home.categories.heading}
      to={browseLink}
    >
      {homePanels.slice(0, 4).map((panel, index) => (
        <CategoryPanel
          key={`panel-${index}`}
          screenType={props.screenType}
          className="row"
          panel={panel}
        />
      ))}
    </ListSlider>
  ) : (
    <ContentListSimple
      className={`content-list__categories`}
      contentType="category"
      title={heading}
      linkLabel={
        config.home.categories.linkLabel
          ? config.home.categories.linkLabel
          : 'Browse all'
      }
      pageContext={p.HOME_ARTICLES}
    >
      {homePanels.map((panel, ind) => (
        <CategoryPanel
          key={`panel-${ind}`}
          screenType={props.screenType}
          panel={panel}
        />
      ))}
    </ContentListSimple>
  )
}

ListCategories.propTypes = {
  screenType: PropTypes.any
}

export default ListCategories
