import React from 'react'
import { useQuery } from '@apollo/client'
import { GET_CONTENTS, getArticlesQueryVariables } from 'Queries'
import { ErrorView } from 'Blocks'
import Component from './component'
import ArticlesLoading from './components/ArticlesLoading'
import config from 'Config'
import { skin } from 'Utils'
import skins from './skins'

const ListArticlesContainer = props => {
  const variables = getArticlesQueryVariables(
    '',
    config.articles.homeArticles.limit,
    'article',
    true
  )
  const { loading, error, data } = useQuery(GET_CONTENTS, { variables })
  if (!config.articlesEnabled) return null
  if (loading) return <ArticlesLoading />
  if (error) return <ErrorView message={error.message} error={error} />
  return <Component {...props} data={data} />
}

export default skin(ListArticlesContainer, skins)
