import { css } from '@emotion/core'

const plain = ({
  theme: {
    components: { icon }
  }
}) => css`
  height: 18px;
  width: 18px;
  transition: all 0.2s ease-in-out;
`
export default plain
