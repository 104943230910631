import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { useQuery } from '@apollo/client'
import { Spinner, ErrorView } from 'Blocks'
import { GET_CATEGORIES } from 'Queries'
import {
  closeNavPanel,
  setActiveTopLevelCategory,
  setCurrentPath,
  setActiveContentType
} from 'Actions'
import config from 'Config'
import Component from './component'

const queryVariables = {
  arguments: {
    categoryUuid: process.env.ANT_BROWSE_ID
  },
  pagination: {
    size: 100,
    page: 1
  }
}

const TopLevelCategoriesContainer = props => {
  const { loading, error, data, client } = useQuery(GET_CATEGORIES, {
    variables: queryVariables
  })

  if (loading) return <Spinner />
  if (error) return <ErrorView message={error.message} error={error} />
  return (
    <Component
      categoryTree={props.categoryTree}
      screenType={props.screenType}
      categories={data.categories}
      siteWideContainer={props.siteWideContainer}
      closeNavPanel={props.closeNavPanel}
      setActiveTopLevelCategory={props.setActiveTopLevelCategory}
      setCurrentPath={props.setCurrentPath}
      currentPathName={props.currentPathName}
      favouritesEnabled={config.favouritesEnabled}
    />
  )
}

TopLevelCategoriesContainer.propTypes = {
  categoryTree: PropTypes.array,
  closeNavPanel: PropTypes.func,
  currentPathName: PropTypes.any,
  screenType: PropTypes.string,
  setActiveTopLevelCategory: PropTypes.func,
  setCurrentPath: PropTypes.func,
  siteWideContainer: PropTypes.any
}

const mapStateToProps = state => {
  return {
    screenType: state.UI.screenType,
    currentPathName: state.UI.location.pathname,
    categoryTree: state.UI.mainMenu.categoryTree
  }
}

const mapDispatchToProps = dispatch => ({
  closeNavPanel: bindActionCreators(closeNavPanel, dispatch),
  setActiveTopLevelCategory: bindActionCreators(
    setActiveTopLevelCategory,
    dispatch
  ),
  setCurrentPath: bindActionCreators(setCurrentPath, dispatch),
  setActiveContentType: bindActionCreators(setActiveContentType, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TopLevelCategoriesContainer)
