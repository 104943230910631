import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Link } from 'Blocks'
import { path, sendTrackingEvent } from 'Utils'
import { NAV_PANEL_CONTENTS, PAGE_CONTEXT as p } from 'Constants'
import config from 'Config'

class ContentsLink extends Component {
  static propTypes = {
    activeContentType: PropTypes.any,
    className: PropTypes.any,
    closeNavPanel: PropTypes.func,
    isTouch: PropTypes.any,
    item: PropTypes.shape({
      label: PropTypes.any,
      to: PropTypes.any,
      type: PropTypes.any
    }),
    navPanel: PropTypes.string,
    openNavPanel: PropTypes.func,
    screenType: PropTypes.string,
    setActiveContentType: PropTypes.func,
    setActiveTopLevelCategory: PropTypes.func,
    setCurrentPath: PropTypes.func,
    setHomeTabMode: PropTypes.func
  }

  componentDidMount() {
    this.mouseIsOver = false
  }

  clearActiveTopLevelCategory = () => {
    this.props.setActiveTopLevelCategory({ name: '', uuid: '' })
    this.props.setCurrentPath([])
  }

  handleClick = to => {
    to && this.props.setHomeTabMode(to)
    if (config.useMainMenu) {
      this.clearActiveTopLevelCategory()
      this.props.setActiveContentType('')
      this.props.closeNavPanel()
    }
  }

  handleMouseEnter = () => {
    this.mouseIsOver = true
    const { item } = this.props
    const delay = this.props.navPanel !== 'menu' ? 300 : 0
    this.delayOpenNav = setTimeout(() => {
      if (this.mouseIsOver) {
        this.props.openNavPanel(NAV_PANEL_CONTENTS.CONTENTS_MENU)
        this.props.setActiveTopLevelCategory({ name: '', uuid: '' })
        this.props.setActiveContentType(item.type)
      }
    }, delay)
  }

  handleMobileClick = item => {
    this.props.openNavPanel(NAV_PANEL_CONTENTS.CONTENTS_MENU)
    this.props.setActiveTopLevelCategory({ name: '', uuid: '' })
    this.props.setActiveContentType(item.type)
  }

  handleMouseLeave = () => {
    this.mouseIsOver = false
  }

  render() {
    const { item, activeContentType } = this.props
    const activeClass =
      this.props.navPanel === 'contents' && item.type === activeContentType
        ? 'active'
        : ''

    const renderMainMenuItem = () => {
      return this.props.screenType === 'small' ? (
        <span
          className={`${activeClass} ${this.props.className}`}
          key={`extra-${item.label}`}
          onClick={() => {
            this.handleMobileClick(item)
          }}
        >
          {item.label}
        </span>
      ) : this.props.isTouch ? (
        <span
          className={`${activeClass} ${this.props.className}`}
          key={`extra-${item.label}`}
          onMouseEnter={() => {
            this.handleMouseEnter()
          }}
          onMouseLeave={this.handleMouseLeave}
        >
          {item.label}
        </span>
      ) : (
        <Link
          className={`${activeClass} ${this.props.className}`}
          onClick={() => this.handleClick(item.to)}
          onMouseEnter={this.handleMouseEnter}
          onMouseLeave={this.handleMouseLeave}
          key={`extra-${item.label}`}
          to={path(p[item.to])}
        >
          {item.label}
        </Link>
      )
    }

    return config.useMainMenu ? (
      renderMainMenuItem()
    ) : (
      <Link
        className={`${activeClass} ${this.props.className}`}
        key={`extra-${item.label}`}
        to={path(p[item.to])}
      >
        {item.label}
      </Link>
    )
  }
}

export default ContentsLink
