import PropTypes from 'prop-types'
import React, { Component, Fragment } from 'react'
import { PAGE_CONTEXT as p } from 'Constants'
import { PageHelmet, SuggestionsTemplate } from 'Blocks'

const NotFoundMessage = props => {
  return (
    <div className="no-match__info error__page">
      <h2>404 ERROR</h2>
      <p className="no-match__spacing">
        The page you are looking for no longer exists.
      </p>
      <p>
        Looking for something in particular? <br />
        Search or browse from our categories.
      </p>
    </div>
  )
}

class NoMatch extends Component {
  static propTypes = {
    className: PropTypes.string,
    staticContext: PropTypes.shape({
      status: PropTypes.any
    })
  }

  render() {
    if (this.props.staticContext) {
      this.props.staticContext.status = 404
    }

    const pageName = p.NOT_FOUND

    return (
      <div className={`main-container ${this.props.className}`}>
        <PageHelmet
          pageName={pageName}
          description={`Either something went wrong or the page doesn't exist anymore.`}
        />
        <SuggestionsTemplate {...this.props}>
          <NotFoundMessage {...this.props} />
        </SuggestionsTemplate>
      </div>
    )
  }
}

export default NoMatch
