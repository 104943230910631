import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { ResultsButton, Icon } from 'Blocks'
import { SCREEN_TYPE } from 'Constants'

class NavPanel extends Component {
  static propTypes = {
    children: PropTypes.any,
    className: PropTypes.string,
    isActive: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.number,
      PropTypes.string
    ]),
    queryString: PropTypes.any,
    screenType: PropTypes.any,
    showButtonOnFilters: PropTypes.string,
    toggleNavPanel: PropTypes.func
  }

  constructor(props) {
    super(props)
    this.navPanelRef = React.createRef()
    this.state = {
      elementOffset: 200
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.screenType === SCREEN_TYPE.SMALL &&
      prevProps.screenType !== SCREEN_TYPE.SMALL &&
      this.props.showButtonOnFilters === 'filters'
    ) {
      this.handleClose()
    }
  }

  handleRef = r => {
    if (r !== null) {
      this.setState({ elementOffset: r.offsetTop })
      this.navPanelRef.current = r
    }
  }

  handleClose = () => this.props.toggleNavPanel()

  render() {
    const { isActive = false, children, className = '' } = this.props
    const noCloseButton =
      this.props.screenType !== SCREEN_TYPE.SMALL && isActive

    return isActive ? (
      <div ref={this.handleRef} className={`nav-panel ${className}`}>
        {!noCloseButton && (
          <div onClick={this.handleClose} className="nav-panel__close">
            <Icon name="close" />
          </div>
        )}
        {children}
        {this.props.showButtonOnFilters === 'filters' && (
          <div className="nav-panel__fixed-wrapper">
            <ResultsButton
              handleClick={this.handleClose}
              queryString={this.props.queryString}
              className="nav-panel__results-button"
            >
              See Results
            </ResultsButton>
          </div>
        )}
      </div>
    ) : null
  }
}

export default NavPanel
