import PropTypes from 'prop-types'
import React from 'react'
import { contentPropTypes } from 'Types'
import { ContentListSimple, ContentCard, ListSlider } from 'Blocks'
import { path, resolveContentCardSkins } from 'Utils'
import config from 'Config'
import { PAGE_CONTEXT as p, SCREEN_TYPE } from 'Constants'

const ListInstalooks = props => {
  return props.screenType === SCREEN_TYPE.SMALL ||
    props.screenType === SCREEN_TYPE.MEDIUM ? (
    <ListSlider
      variant="instalook"
      header={config.instalooks.homeInstalooks.title}
      to={path(p.INSTALOOK_LIST)}
    >
      {props.data.contents.contents.slice(0, 4).map((content, index) => (
        <ContentCard
          key={`content-${content.uuid}`}
          position={index + 1}
          useCategoryName={true}
          variant={resolveContentCardSkins(p.HOME_INSTALOOKS)}
          content={content}
        />
      ))}
    </ListSlider>
  ) : (
    <ContentListSimple
      className="container content-list__instalooks"
      contentType="instalook"
      contents={props.data.contents.contents}
      title={config.instalooks.homeInstalooks.title}
      linkLabel={config.instalooks.homeInstalooks.showMore}
      pageContext={p.HOME_INSTALOOKS}
    />
  )
}

ListInstalooks.propTypes = {
  data: PropTypes.shape({
    contents: PropTypes.shape({
      contents: PropTypes.arrayOf(PropTypes.shape({ ...contentPropTypes }))
    })
  }),
  screenType: PropTypes.any
}

export default ListInstalooks
