import PropTypes from 'prop-types'
import React from 'react'
import { ContentListSimple } from 'Blocks'
import config from 'Config'
import { PAGE_CONTEXT as p } from 'Constants'

const ListTrends = props => {
  return (
    <ContentListSimple
      className={`content-list__trends container ${props.className}`}
      contentType="trend"
      contents={props.data.contents.contents}
      title={config.trends.homeTrends.title}
      linkLabel={config.trends.main.showMore}
      pageContext={p.HOME_TRENDS}
      pageName={p.HOME}
    />
  )
}

ListTrends.propTypes = {
  className: PropTypes.any,
  data: PropTypes.shape({
    contents: PropTypes.shape({
      contents: PropTypes.any
    })
  })
}

export default ListTrends
