import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { routerPropTypes } from 'Types'
import { withRouter } from 'react-router'
import zip from 'lodash/zip'
import { splitToN, UrlBuilder, formatNumber } from 'Utils'
import { SendGA } from '../utils'
import { PRICE_RANGE_LIMIT, PRICE_RANGE_ENTRIES as PRE } from 'Constants'
import { Icon } from 'Blocks'
import config from 'Config'

class PriceFilter extends Component {
  static propTypes = {
    className: PropTypes.any,
    ...routerPropTypes,
    price_filters: PropTypes.shape({
      max: PropTypes.any,
      min: PropTypes.any
    }),
    queryString: PropTypes.string,
    setPriceFilters: PropTypes.func
  }

  setPrice(min, max) {
    const newQuery = new UrlBuilder(this.props.queryString)
    min ? newQuery.addMinPrice(min) : newQuery.removeMinPrice()
    max ? newQuery.addMaxPrice(max) : newQuery.removeMaxPrice()

    newQuery.setPage(1)
    this.props.history.push(newQuery.getQueryString())
  }

  cancelPrice() {
    const newQuery = new UrlBuilder(this.props.queryString)
    newQuery.setPage(1).removeMinPrice().removeMaxPrice()

    this.props.history.push(newQuery.getQueryString())
  }

  resolveSelected(displayMin, displayMax) {
    const matchMin = this.props.queryString.match(/min=(\d+)/)
    const matchMax = this.props.queryString.match(/max=(\d+)/)
    const minUrl = matchMin && parseInt(matchMin[1])
    const maxUrl = matchMax && parseInt(matchMax[1])
    return (
      (minUrl === displayMin && maxUrl === displayMax) ||
      (minUrl === displayMin && !maxUrl && !displayMax) ||
      (maxUrl === displayMax && !minUrl && !displayMin)
    )
  }

  handlePriceSet(min, max) {
    if (this.resolveSelected(min, max)) {
      this.props.setPriceFilters(false)
      this.cancelPrice()
    } else {
      this.props.setPriceFilters(true)
      this.setPrice(min, max)
      // GA
      const isClient = typeof window !== 'undefined'
      if (isClient) {
        const eventLabel = `minPrice=${min} - maxPrice=${max}`
        SendGA(eventLabel, { minPrice: parseInt(min), maxPrice: parseInt(max) })
      }
    }
  }

  resolveClassName(min, max) {
    return this.resolveSelected(min, max) ? 'selected' : undefined
  }

  isSelected(min, max) {
    return this.resolveSelected(min, max)
  }

  renderIntervals() {
    const pf = this.props.price_filters
    const intervals = splitToN(pf.max - pf.min, PRICE_RANGE_LIMIT)
    if (intervals.length) {
      const minimums = intervals.map((item, index) =>
        intervals.slice(0, index).reduce((t, c) => t + c, pf.min)
      )
      const maximums = minimums.slice(1, minimums.length).concat(pf.max)
      return zip(minimums, maximums)
        .map(item => [PRE[item[0]], PRE[item[1]]])
        .map(item => {
          const [displayMin, displayMax] = item
          return (
            <li
              key={displayMin}
              className={this.resolveClassName(displayMin, displayMax)}
              onClick={() => this.handlePriceSet(displayMin, displayMax)}
            >
              {config.filters.price.currencySymbol
                ? config.filters.price.currencySymbol
                : '£'}
              {formatNumber(displayMin)} -{' '}
              {config.filters.price.currencySymbol
                ? config.filters.price.currencySymbol
                : '£'}
              {formatNumber(displayMax)}
              {this.isSelected(displayMin, displayMax) && (
                <Icon name="close" className="close-icon" />
              )}
            </li>
          )
        })
    } else {
      return null
    }
  }

  render() {
    const { min, max } = this.props.price_filters
    return (
      <div className={this.props.className}>
        <ul>
          <li
            className={this.resolveClassName(undefined, PRE[min])}
            onClick={() => this.handlePriceSet(undefined, PRE[min])}
          >
            {config.filters.price.belowLabel
              ? config.filters.price.belowLabel
              : 'Below'}{' '}
            {config.filters.price.currencySymbol
              ? config.filters.price.currencySymbol
              : '£'}
            {formatNumber(PRE[min])}
            {this.isSelected(undefined, PRE[min]) && (
              <Icon name="close" className="close-icon" />
            )}
          </li>
          {this.renderIntervals()}
          <li
            className={this.resolveClassName(PRE[max], undefined)}
            onClick={() => this.handlePriceSet(PRE[max], undefined)}
          >
            {config.filters.price.currencySymbol
              ? config.filters.price.currencySymbol
              : '£'}
            {formatNumber(PRE[max])} &{' '}
            {config.filters.price.belowLable
              ? config.filters.price.aboveLable
              : 'Above'}
            {this.isSelected(PRE[max], undefined) && (
              <Icon name="close" className="close-icon" />
            )}
          </li>
        </ul>
      </div>
    )
  }
}

export default withRouter(PriceFilter)
