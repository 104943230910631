import PropTypes from 'prop-types'
import React from 'react'
import config from 'Config'

const ResultsButton = props => {
  const str = props.queryString || ''
  const cmp = [
    'price',
    'order',
    'store',
    'sale',
    'max',
    'min',
    'category',
    'color'
  ]
    .map(el => {
      if (str.indexOf(el) > -1) {
        return true
      } else {
        return false
      }
    })
    .includes(true)
  const res = cmp ? '' : 'disabled'

  return (
    <button
      className={props.className}
      disabled={res}
      onClick={props.handleClick}
    >
      {config.locale === 'de' ? 'Siehe Ergebnisse' : 'See results'}
    </button>
  )
}

ResultsButton.propTypes = {
  className: PropTypes.any,
  handleClick: PropTypes.any,
  queryString: PropTypes.string
}

export default ResultsButton
