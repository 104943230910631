import PropTypes from 'prop-types'
import React, { Fragment, useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import loadable from '@loadable/component'
import get from 'lodash/get'
import {
  ToTopButton,
  TopLevelCategories,
  PreloadImages,
  NavPanel,
  NavPanelOverlay,
  PanelFilters,
  MainMenu,
  ContentsMenu,
  GetInitialFavourites
} from 'Blocks'
import { storeClickref } from 'Utils'
import CookiePolicy from '../../CookiePolicy'
import { NAV_PANEL_CONTENTS } from 'Constants'
import config from 'Config'

const CategoryTree = loadable(() => import('Data/CategoryTree'))

const Page = props => {
  useEffect(() => {
    if (config.altClickrefEnabled) {
      storeClickref(props.location)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (
    props.location.pathname.indexOf(`${process.env.ANT_PREFIX}/embed/`) > -1
  ) {
    return props.children
  }

  let navPanelChild = {
    [NAV_PANEL_CONTENTS.FILTERS]: <PanelFilters loading={props.loading} />,
    [NAV_PANEL_CONTENTS.MENU]: <MainMenu />,
    [NAV_PANEL_CONTENTS.CONTENTS_MENU]: <ContentsMenu />,
    '': () => null
  }[props.navPanel]

  return (
    <Fragment>
      {config.theme && config.theme === 'v1' ? (
        <>{props.children}</>
        ) : (
          <>
            <GetInitialFavourites />
            <PreloadImages />
            <CategoryTree />
            <div className={`${props.className} app-container`}>
              <div className="site-wide">
                <TopLevelCategories />
                <NavPanel>{navPanelChild}</NavPanel>
                <NavPanelOverlay />
              </div>
              {props.children}
            </div>
          </>
        )
      }
      {config.showCookiePolicy && !config.theme ? <CookiePolicy /> : null}
      {!config.theme ? <ToTopButton /> : null}
    </Fragment>
  )
}

Page.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  loading: PropTypes.any,
  location: PropTypes.shape({
    pathname: PropTypes.string
  }),
  navPanel: PropTypes.any
}
const mapStateToProps = state => ({
  navPanel: get(state, 'UI.navPanel', ''),
  loading: get(state, 'UI.loading', false)
})

export default connect(mapStateToProps)(withRouter(Page))
