import PropTypes from 'prop-types'
import React from 'react'
import {
  ListArticles,
  ListCategories,
  ListInstalooks,
  ListTrends
} from 'Blocks'

const SuggestionsTemplate = props => {
  const queryString = props.location.search
  return (
    <div className={props.className}>
      <div className="info-wrapper">{props.children}</div>
      <div className="container__outer--grey">
        <ListCategories />
      </div>
      <ListArticles section="first" />
      <div className="container__outer--black">
        <ListInstalooks />
      </div>
      <ListTrends queryString={queryString} />
    </div>
  )
}

SuggestionsTemplate.propTypes = {
  children: PropTypes.any,
  className: PropTypes.any,
  location: PropTypes.shape({
    search: PropTypes.any
  }),
  queryString: PropTypes.string
}

SuggestionsTemplate.defaultProps = {
  queryString: ''
}

export default SuggestionsTemplate
