import PropTypes from 'prop-types'
import React, { Component } from 'react'
import AliceCarousel from 'react-alice-carousel'
import { Link } from 'Blocks'
import config from 'Config'

class ListSlider extends Component {
  static propTypes = {
    children: PropTypes.any,
    className: PropTypes.any,
    header: PropTypes.any,
    to: PropTypes.any,
    variant: PropTypes.any
  }

  responsive = {
    0: { items: 1 },
    640: { items: 2 }
  }

  render() {
    const variantClass = this.props.variant
      ? `slider__${this.props.variant}`
      : ``
    const { to, header } = this.props
    return (
      <div className={`${this.props.className} slider ${variantClass}`}>
        <div className="slider-header">
          <h2 className="slider-title">{header}</h2>
          <Link className="slider-cta" to={to}>
            {config.instalooks.homeInstalooks.showMore}
          </Link>
        </div>

        <AliceCarousel
          items={this.props.children}
          responsive={this.responsive}
          buttonsDisabled={true}
          mouseTrackingEnabled={true}
        />
      </div>
    )
  }
}

export default ListSlider
