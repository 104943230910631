import { css } from '@emotion/core'
import theme from 'Theme'

const {
  colors,
  spacing,
  layout,
  mixins: { mainContainerMaxWidth }
} = theme

export default () => css`
  .no-search__related {
    width: 100%;
    background-color: ${colors.white};
    padding-top: ${spacing.vertical.small};
  }

  .content-list__articles,
  .content-list__trends,
  .content-list__categories,
  .content-list__instalooks {
    ${mainContainerMaxWidth(theme)}
  }

  .container__outer--grey,
  .content-list__articles {
    padding-bottom: ${spacing.base_3}px;
  }

  .container__outer--grey {
    background-color: ${colors.cardGrey};
  }

  .container__outer--black {
    background-color: black;
    .content-list__instalooks {
      padding-bottom: ${spacing.base_6}px;
    }
    .content-card {
      &:hover {
        box-shadow: none;
      }
    }
  }

  .info-wrapper {
    width: 100%;
    background-color: ${colors.white};
    padding-bottom: ${spacing.vertical.small};
  }

  .related-trends {
    width: 93%;
    max-width: ${layout.maxWidth.mainContainer}px;
    margin: 0 auto;
  }

  .related-articles {
    width: 93%;
    max-width: ${layout.maxWidth.mainContainer}px;
    margin: 0 auto 0 auto;
    padding-bottom: ${spacing.vertical.medium};
  }
`
