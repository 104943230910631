// https://www.geeksforgeeks.org/split-the-number-into-n-parts-such-that-difference-between-the-smallest-and-the-largest-part-is-minimum/

export const splitToN = (x, n) => {
  let results = []
  if (x <= 0) {
    // do nothing
  } else if (x < n) {
    results = Array(x).fill(1)
  } else if (x % n === 0) {
    results = Array(n).fill(x / n)
  } else {
    const zp = n - (x % n)
    const pp = Math.floor(x / n)
    for (let i = 0; i < n; i++) {
      if (i >= zp) {
        results.push(pp + 1)
      } else {
        results.push(pp)
      }
    }
  }
  return results
}
