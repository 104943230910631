import { css } from '@emotion/core'
import theme from 'Theme'
import { smallOnly } from 'Utils'

const { colors, typography, mixins, spacing } = theme

const { mainContainerMaxWidth } = mixins

export const plain = () => css`
  &.content-list__loading {
    ${mainContainerMaxWidth(theme)}
    margin: 1rem auto;

    .content-list__title {
      margin-bottom: 1rem;
      ${mixins.loadingLight(theme)}
      width: calc(25% - 15px);
      height: 3rem;
    }

    .content-list__grid {
      height: 310px;
    }

    .content-list__grid-item {
      height: 310px;
      ${mixins.loadingLight(theme)}
    }
  }

  .content-list__header {
    @media ${smallOnly} {
      justify-content: space-between;
    }
    justify-content: flex-start;
    display: flex;
    padding: ${spacing.base_3}px 0;
    position: relative;
    align-items: center;
  }

  &.content-list__instalooks {
    .content-list__header,
    .content-list__cta {
      color: white;
      border-color: white;
    }
  }

  .content-list__title {
    font-size: ${typography.sizes.large};
    margin-right: ${spacing.base_3}px;
    @media ${smallOnly} {
      font-size: ${typography.sizes.larger};
    }
  }

  .content-list__cta {
    @media ${smallOnly} {
      flex-shrink: 0;
    }
    position: relative;
    font-size: ${typography.sizes.small};
    display: inline-block;
    line-height: 1.3;
    border-bottom: 2px solid ${colors.body};
    &:hover {
      border-color: ${colors.borderGrey};
    }
  }

  .content-list__grid {
  }
`

export default plain
