import productOverview from './productOverview'
import productCard from './productCard'
import icon from './icon'
import articleCard from './articleCard'
import articleItem from './articleItem'
import articleTarget from './articleTarget'

export default {
  articleCard,
  articleItem,
  productOverview,
  productCard,
  icon,
  articleTarget
}
