import { css } from '@emotion/core'
import { smallOnly, mediumUp } from 'Utils'
import theme from 'Theme'

const { mixins, typography, spacing, colors } = theme

export default () => {
  return css`
    background-color: ${colors.cardGrey};
    position: relative;
    display: flex;
    flex-direction: column;

    @media ${mediumUp} {
      &:hover {
        ${mixins.cardBoxShadow(theme)}
      }
    }

    .content-card__image-wrapper {
      img {
        object-fit: cover;
        height: 300px;
        width: 100%;
        @media ${smallOnly} {
          height: 350px;
        }
      }
    }

    .content-card__info {
      padding: ${spacing.base_2}px;
      position: relative;
    }

    .content-card__title {
      font-size: ${typography.sizes.large};
      font-weight: bold;
      word-break: break-word;
      line-height: 1.3;
      text-align: center;
    }

    .content-card__category {
      display: none;
    }
  `
}
