import React from 'react'
import Component from './component'
import { string, number } from 'prop-types'
import skins from './skins'
import { skin } from 'Utils'

class SpinnerContainer extends React.Component {
  state = {
    showSpinner: false
  }

  componentDidMount() {
    const delay = this.props.delay || 1000
    this.delaySpinner = setTimeout(() => {
      this.setState({
        showSpinner: true
      })
    }, delay)
  }

  componentWillUnmount() {
    clearTimeout(this.delaySpinner)
  }

  render() {
    const showSpinner = this.state.showSpinner ? 'show-spinner' : ''
    const { className = '' } = this.props
    return (
      <div className={`spinner loader-container ${className} ${showSpinner}`}>
        <Component />
      </div>
    )
  }
}

SpinnerContainer.propTypes = {
  className: string,
  delay: number
}

export default skin(SpinnerContainer, skins)
