import { css } from '@emotion/core'

export default ({ theme: { colors } }) => css`
  @keyframes fadeout {
    from {
      opacity: 1;
      z-index: 9999;
    }
    to {
      opacity: 0;
      z-index: 0;
    }
  }

  &.fetching {
    .loader {
      z-index: 9999;
    }
  }

  &.fetched {
    .loader {
      animation: fadeout 600ms linear;
    }
  }

  width: 100%;
  height: 100%;
  padding-top: 4rem;
  background-color: rgba(255, 255, 255, 0.65);
  display: flex;

  align-items: flex-start;
  padding-top: 4rem;
  z-index: 99;
  .loader,
  .loader:after {
    border-radius: 50%;
    width: 10em;
    height: 10em;
  }
  .loader {
    margin: 60px auto;
    font-size: 10px;
    position: relative;
    text-indent: -9999em;
    border-top: 1.1em solid rgba(0, 0, 0, 0.4);
    border-right: 1.1em solid rgba(0, 0, 0, 0.4);
    border-bottom: 1.1em solid rgba(0, 0, 0, 0.4);
    border-left: 1.1em solid #000;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 1.1s infinite linear;
    animation: load8 1.1s infinite linear;
  }
  @-webkit-keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  opacity: 0;

  &.show-spinner {
    opacity: 1;
  }
`
