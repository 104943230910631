import PropTypes from 'prop-types'
import React, { useState, useRef, Fragment, useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import get from 'lodash/get'
import {
  setCurrentPath,
  closeNavPanel,
  setActiveTopLevelCategory,
  setSerachHint
} from 'Actions'
import { Icon } from 'Blocks'
import { path } from 'Utils'
import config from 'Config'
import { PAGE_CONTEXT as p } from 'Constants'
import { withRouter } from 'react-router'
const mapStateToProps = state => {
  return {
    location: state.UI.location,
    navPanel: state.UI.navPanel
  }
}

const mapDispatchToProps = dispatch => ({
  closeNavPanel: bindActionCreators(closeNavPanel, dispatch),
  setActiveTopLevelCategory: bindActionCreators(
    setActiveTopLevelCategory,
    dispatch
  ),
  setCurrentPath: bindActionCreators(setCurrentPath, dispatch),
  setSerachHint: bindActionCreators(setSerachHint, dispatch)
})

const ANT_BROWSE_ID = process.env.ANT_BROWSE_ID

const searchUrl = path(p.PRODUCT_LIST, {
  uuid: ANT_BROWSE_ID,
  subCategory: 'search'
})

const Search = props => {
  const {
    showSubmitButton = false,
    showSubmitButtonText = false,
    showSubmitButtonIcon = false,
    className = '',
    location
  } = props

  const pathname = get(location, 'pathname', '')
  const searchForm = useRef(null)
  const [term, setTerm] = useState('')
  const [visible, setVisible] = useState(false)
  const isVisible = props.useToggle ? visible : true
  const modifier = props.useToggle ? 'use-toggle' : ''

  useEffect(() => {
    const isSearch = pathname.indexOf('search') > -1
    if (!isSearch) {
      setTerm('')
    }
  }, [pathname])

  function handleFormSubmission(e) {
    e.preventDefault()
    const term = e.target.elements.term.value
    setTerm(term)
    const queryString = `?term=${term}`

    if (term) {
      props.history.push(`${searchUrl}${queryString}`)
    } else {
      props.history.push(
        path(p.PRODUCT_LIST, { uuid: ANT_BROWSE_ID, subCategory: 'view_all' })
      )
    }

    // reset top level category menu
    props.setActiveTopLevelCategory({ name: '', uuid: '' })
    props.setCurrentPath([])

    if (props.navPanel) {
      props.closeNavPanel()
    }
  }

  function closeSearch(e) {
    if (searchForm.current && !searchForm.current.contains(e.target)) {
      setVisible(false)
    }
  }

  useEffect(() => {
    if (visible) {
      document.addEventListener('click', closeSearch)
    } else {
      document.removeEventListener('click', closeSearch)
    }
    return () => {
      document.removeEventListener('click', closeSearch)
    }
  }, [visible])

  return (
    <Fragment>
      {isVisible && (
        <form
          ref={searchForm}
          className={`search-form ${modifier} ${className}`}
          onSubmit={e => handleFormSubmission(e)}
          role="search"
          action={searchUrl}
        >
          <input
            className="search-input"
            placeholder={config.siteWide.searchPlaceholderText}
            name="term"
            autoComplete="off"
            value={term}
            onChange={e => setTerm(e.target.value)}
            autoFocus
          />
          {showSubmitButton && (
            <button className="search-submit" type="submit" value="Submit">
              {showSubmitButtonText && (
                <span>{config.siteWide.submitButtonText}</span>
              )}
              {showSubmitButtonIcon && (
                <Icon
                  className="search-icon"
                  name={config.siteWide.submitButtonIcon}
                />
              )}
            </button>
          )}
        </form>
      )}

      {props.useToggle && (
        <div
          onClick={() => setVisible(!visible)}
          className={`toggle-search ${className}`}
        >
          <Icon className="search-icon" name={visible ? 'close' : 'search'} />
        </div>
      )}
    </Fragment>
  )
}

Search.propTypes = {
  className: PropTypes.string,
  closeNavPanel: PropTypes.func,
  history: PropTypes.shape({
    push: PropTypes.func
  }),
  location: PropTypes.any,
  navPanel: PropTypes.any,
  setActiveTopLevelCategory: PropTypes.func,
  setCurrentPath: PropTypes.func,
  showSubmitButton: PropTypes.bool,
  showSubmitButtonIcon: PropTypes.bool,
  showSubmitButtonText: PropTypes.bool,
  useToggle: PropTypes.any
}

Search.displayName = 'Search'

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Search))
