import { connect } from 'react-redux'
import Component from './component'
import skins from './skins'
import { skin } from 'Utils'

const mapStateToProps = state => {
  return {
    screenType: state.UI.screenType || ' '
  }
}

export default skin(connect(mapStateToProps, null)(Component), skins)
