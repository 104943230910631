import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { addFavouritesFromLocalStorage } from 'Actions'
import { bindActionCreators } from 'redux'
import { useLocalStorage, isClient } from 'Utils'

const GetInitialFavourites = (props) => {
  const [uuids] = useLocalStorage('favouritesUuids', [])

  useEffect(() => {
    if (Array.isArray(uuids) && uuids.length > 0) {
      props.addFavouritesFromLocalStorage(uuids)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return null
}

const mapDispatchToProps = dispatch => ({
  addFavouritesFromLocalStorage: bindActionCreators(addFavouritesFromLocalStorage, dispatch)
})

const Component = isClient ? GetInitialFavourites : () => null

export default connect(null, mapDispatchToProps)(Component)
