const baseUnit = 10

export const spacing = {
  base_1: baseUnit,
  base_2: baseUnit * 1.5,
  base_3: baseUnit * 2,
  base_4: baseUnit * 3,
  base_5: baseUnit * 4,
  base_6: baseUnit * 6.5,
  vertical: {
    small: '10px',
    medium: '20px',
    large: '30px'
  }
}

export default spacing
