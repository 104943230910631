import { gql } from '@apollo/client'
import { categoryFragment } from 'Fragments'

const GET_CATEGORIES = /* GraphQL */ gql`
  query categories($arguments: GetCategories!, $pagination: Pagination!) {
    categories(arguments: $arguments, pagination: $pagination) {
      _cache
      categories {
        ...categoryFields
      }
    }
  }

  ${categoryFragment}
`

export default GET_CATEGORIES
