import { mediumUp, largeUp } from 'Utils'

export const defaultButtonPadding = () => `
  padding: .75rem 3rem;
`

export const defaultButton = (theme = {}) => `
  font-family: ${theme.typography.headings.fontFamily};
  display: inline-flex;
  justify-content: center;
  align-items: center;
  ${defaultButtonPadding()}
  vertical-align: middle;
  text-align: center;
  line-height: 1em;
  letter-spacing: ${theme.typography.headings.subHeadLetterSpacing};
  font-size: ${theme.typography.baseFontSize};
  font-weight: bold;
  border-radius: 'initial';
  transition: all 150ms ease-in;
  min-width: 230px;    
  background-color: ${theme.colors.bodyBackground};
  border: 1px solid ${theme.colors.primary};
  color: ${theme.colors.primary};
  transition: background-color 100ms ease-in, color 100ms ease-in;
  &:hover {
    text-decoration: none;
    cursor: pointer;
    color: ${theme.colors.bodyBackground};
    background-color: ${theme.colors.darkGrey};
    border-color: ${theme.colors.darkGrey};
  }  
`

const mainContainerMaxWidth = (theme = {}) => `
  max-width: calc(100% - ${theme.spacing.base_3 * 2}px);
  margin: 0 auto;
  @media ${mediumUp} {
    max-width: ${theme.layout.maxWidth.page}px;
  }
  @media screen and (max-width: ${theme.layout.maxWidth.page}px) {
    max-width: 95%;
  }
`

export const mainContainerTopPadding = (theme = {}) => `
  padding-top:${theme.spacing.vertical.medium};
  @media ${largeUp} {
    padding-top: ${theme.spacing.vertical.large};
  }
  `

const subText = () => `
  font-weight: normal;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: #212529;
  font-size: 12px;
`

export const cardOverlay = () => `
  &:after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: block;
    opacity: 0;
    background-color: rgba(0,0,0,0.3);
    transition: opacity 0.2s ease-out;
  }
`

export const loadingLight = (theme = {}) => {
  const {
    colors: {
      loading: {
        panel: { light }
      }
    }
  } = theme
  const { backgroundColor, animationColor } = light
  return `
  width: 100%;
  background: linear-gradient(270deg, ${backgroundColor}, ${backgroundColor}, ${animationColor}, ${backgroundColor}, ${backgroundColor}, ${backgroundColor});
  background-size: 1200% 1200%;
  -webkit-animation: Loading 6s ease infinite;
  -moz-animation: Loading 6s ease infinite;
  animation: Loading 6s ease infinite;

  @-webkit-keyframes Loading {
      0%{background-position:0% 50%}
      50%{background-position:100% 50%}
      100%{background-position:0% 50%}
  }
  @-moz-keyframes Loading {
      0%{background-position:0% 50%}
      50%{background-position:100% 50%}
      100%{background-position:0% 50%}
  }
  @keyframes Loading {
      0%{background-position:0% 50%}
      50%{background-position:100% 50%}
      100%{background-position:0% 50%}
  }
`
}

export const defaultHeading = (theme = {}) => `
  font-size: 2rem;
  font-weight: ${theme.typography.headings.fontWeight};
`

export const downArrow = (theme = {}) => {
  return `
    content: ' ';
    height: 0;
    width: 0;
    border: 3px solid transparent;
    border-top: 3px solid ${theme.elementColors.select.downArrow};
  `
}

const defaultVerticalSpacing = (theme = {}) => `
  margin-bottom: ${theme.spacing.vertical.small};
  @media ${mediumUp} {
    margin-bottom: ${theme.spacing.vertical.large};
  }
`

export const articleButton = () => `
  display: inline-block;
  padding: .5rem 1rem;
  color: #666;
  border: 2px solid #F2F2F2;
  @media ${mediumUp} {
    &:hover {
      color: white;
      background-color: black;
      border: 2px solid black;
    }
  }
`

export const seeFilteredButton = (theme = {}) => `
  margin: ${theme.spacing.vertical.medium} auto 0 auto;
  display: block;
  color: ${theme.colors.primary};
  font-family: Arial;
  background-color: ${theme.colors.white};
  font-size: ${theme.typography.sizes.small};
  line-height: 26px;
  border: 1px solid ${theme.colors.primary};
  &:hover {
    background-color: ${theme.colors.primary};
    border: 1px solid ${theme.colors.primary};
    color: ${theme.colors.white};
  }
  &:disabled {
    border: 1px solid ${theme.colors.button.disabled};
    color: ${theme.colors.button.disabled};
    cursor: default;
  }
`

const sectionHeading = (theme = {}) => `
  font-size: ${theme.typography.sizes.large};
  letter-spacing: ${theme.typography.headings.letterSpacing};
  line-height: 1em;
  font-weight: bold;
  color: ${theme.colors.primary};
  text-transform: capitalize;
  @media ${mediumUp} {
    font-size: ${theme.typography.sizes.larger};
  }
`

export default {
  subText,
  cardOverlay,
  defaultHeading,
  articleButton,
  downArrow,
  defaultVerticalSpacing,
  mainContainerMaxWidth,
  mainContainerTopPadding,
  seeFilteredButton,
  loadingLight,
  sectionHeading,
  defaultButtonPadding,
  defaultButton
}
