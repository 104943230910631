import PropTypes from 'prop-types'
import React from 'react'
import { path } from 'Utils'
import { Link, LazyLoadImage } from 'Blocks'
import { PAGE_CONTEXT as p, SCREEN_TYPE } from 'Constants'

const CategoryPanel = ({ panel, className = '', screenType }) => {
  const id = panel.uuid || ' '
  const slug = panel.slug || ' '
  const image = panel.image.large || ' '
  const title = panel.name || ' '
  const description = panel.description || ' '
  const categoryLink = path(p.PRODUCT_LIST, {
    uuid: id,
    subCategory: slug
  })

  return (
    <Link
      data-cy="category"
      className={`${className} category-panel`}
      to={categoryLink}
    >
      <LazyLoadImage
        className="category-panel__image"
        image={image}
        alt={title}
        width={'700'}
      />
      <div className="category-panel__content">
        <h2 className="category-panel__title">{title}</h2>
        {screenType !== SCREEN_TYPE.SMALL && (
          <p className="category-panel__descrition">{description}</p>
        )}
      </div>
    </Link>
  )
}

CategoryPanel.propTypes = {
  className: PropTypes.string,
  panel: PropTypes.shape({
    description: PropTypes.string,
    image: PropTypes.shape({
      large: PropTypes.string
    }),
    name: PropTypes.string,
    slug: PropTypes.string,
    uuid: PropTypes.string
  }),
  screenType: PropTypes.any
}

export default CategoryPanel
