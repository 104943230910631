import { css } from '@emotion/core'
import theme from 'Theme'
const { zIndex, colors } = theme

export const plain = () => {
  let scrollHeight =
    typeof document !== 'undefined'
      ? Math.max(
          document.body.scrollHeight,
          document.documentElement.scrollHeight,
          document.body.offsetHeight,
          document.documentElement.offsetHeight,
          document.body.clientHeight,
          document.documentElement.clientHeight
        )
      : null

  const fullDocumentHeight = scrollHeight ? `${scrollHeight}px` : `200vh`

  return css`
    background-color: ${colors.overlayBackground};
    height: ${fullDocumentHeight};
    width: 100%;
    position: absolute;
    left: 0;
    top: 100%;
    right: 0;
    bottom: 0;
    z-index: ${zIndex.navPanelOverlay};
  `
}

export default plain
