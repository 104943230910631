import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import CategoryLink from './component'
import {
  setCurrentPath,
  setCurrentCategory,
  closeNavPanel,
  openNavPanel,
  toggleNavPanel,
  setActiveTopLevelCategory,
  setActiveContentType
} from 'Actions'

const mapStateToProps = (state, props) => ({
  isTouch: state.UI.isTouch,
  screenType: state.UI.screenType,
  navPanel: state.UI.navPanel,
  activeTopLevelCategory: state.UI.mainMenu.activeTopLevelCategory,
  currentPath: state.UI.mainMenu.currentPath
})

const mapDispatchToProps = dispatch => ({
  setCurrentPath: bindActionCreators(setCurrentPath, dispatch),
  setCurrentCategory: bindActionCreators(setCurrentCategory, dispatch),
  openNavPanel: bindActionCreators(openNavPanel, dispatch),
  closeNavPanel: bindActionCreators(closeNavPanel, dispatch),
  toggleNavPanel: bindActionCreators(toggleNavPanel, dispatch),
  setActiveTopLevelCategory: bindActionCreators(
    setActiveTopLevelCategory,
    dispatch
  ),
  setActiveContentType: bindActionCreators(setActiveContentType, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(CategoryLink)
