import { typography } from '../typography'
import colors from '../colors'

export const articleCard = {
  fontWeight: 'normal',
  fontFamily: typography.headings.fontFamily,
  color: colors.primary
}

export default articleCard
