import { css } from '@emotion/core'
import theme from 'Theme'
import { smallOnly, mediumUp, largeUp } from 'Utils'

const { spacing, colors, typography, mixins } = theme

const smallArticle = () => css`
  background-color: ${colors.bodyBackground};
  position: relative;
  display: flex;
  min-height: 150px;

  @media ${smallOnly} {
    border-bottom: 1px solid ${colors.borderGrey};
    padding-bottom: ${spacing.base_2}px;
  }

  @media ${mediumUp} {
    border: 1px solid ${colors.borderGrey};
    &:hover {
      ${mixins.cardBoxShadow(theme)}
    }
  }

  @media ${largeUp} {
    height: auto;
  }

  .content-card__image-wrapper {
    width: 50%;
    min-height: 150px;
    height: 150px;
    @media ${largeUp} {
      height: auto;
    }

    img {
      width: 100%;
      min-height: 100%;
      height: 150px;
      object-fit: cover;
    }
  }

  .content-card__info {
    padding-left: ${spacing.base_2}px;
    display: flex;
    flex-direction: column;
    flex: 1;
    min-height: 150px;
    height: 150px;
    @media ${mediumUp} {
      padding: ${spacing.base_2}px ${spacing.base_1}px ${spacing.base_2}px
        ${spacing.base_2}px;
    }
    @media ${largeUp} {
      height: auto;
    }
  }

  .content-card__title {
    order: 2;
    line-height: 1.2;
    font-weight: bold;
    word-break: break-word;
    @media ${mediumUp} {
      font-size: ${typography.sizes.small};
    }
  }

  .content-card__category {
    order: 1;
    ${mixins.subText(theme)}
    line-height: 1;
    margin-bottom: ${spacing.base_1}px;
  }

  /*
  reserve space for the image
  avoid layout shifting before the image has loaded
  */
  .content-card__image-wrapper {
    min-height: 150px;
  }
`

export default smallArticle
