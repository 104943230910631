import { css } from '@emotion/core'
import { smallOnly, largeUp } from 'Utils'
import theme from 'Theme'

const { colors, mixins, spacing, layout, zIndex } = theme

export default () => css`
  height: ${layout.navPanel.height}px;
  background-color: ${colors.white};
  width: 100%;
  max-width: ${layout.maxWidth.mainContainer}px;
  z-index: ${zIndex.navPanel};
  position: absolute;
  border-top: 1px solid ${colors.cardGrey};

  @media ${smallOnly} {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 99999;
  }

  .nav-panel__fixed-wrapper {
    background-color: ${colors.white};
    width: 100%;
    max-width: 1600px;
    padding: ${spacing.vertical.medium};
  }

  .nav-panel__results-button {
    width: 200px;
    height: 40px;
    ${mixins.seeFilteredButton(theme)};
    margin: 0 auto;
  }

  .nav-panel__results-button:disabled:hover {
    border: 1px solid ${colors.button.disabled};
    background-color: ${colors.white};
    cursor: default;
  }

  .panel-buttons__apply {
    ${mixins.seeFilteredButton(theme)};
  }

  @media ${largeUp} {
    .nav-panel__content {
      height: ${layout.navPanel.height}px;
      display: flex;
    }

    .nav-panel__heading {
      ${mixins.sectionHeading(theme)}
      color: ${theme.colors.body};
      margin-bottom: ${spacing.vertical.small};
    }

    .content-card {
      margin-bottom: ${spacing.vertical.small};
    }

    .nav-panel__articles-link {
      ${mixins.defaultButton(theme)}
    }
  }

  .nav-panel__close {
    position: absolute;
    right: 0;
    top: 0;
    width: 50px;
    height: 65px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99;
    &:hover {
      cursor: pointer;
    }

    @media ${smallOnly} {
      svg {
        fill: ${colors.complementary.primary};
      }
    }
  }

  @media screen and (min-width: ${layout.maxWidth.mainContainer}px) {
    left: calc(50% - ${layout.maxWidth.mainContainer / 2}px);
  }
`
