import PropTypes from 'prop-types'
import React from 'react'
import {
  path,
  contentCardGALabel,
  sanitize,
  cutTitleToLimit,
  sendTrackingEvent,
  createContentCardGAProps
} from 'Utils'
import config, { gaSettings } from 'Config'
import get from 'lodash/get'
import { Link, LazyLoadImage, Icon } from 'Blocks'
import { SCREEN_TYPE } from 'Constants'

const ContentCard = props => {
  let cardName = 'articleCard'
  if (props.variant === 'Instalooks') {
    cardName = 'instalookCard'
  } else if (props.variant === 'Trend') {
    cardName = 'trendCard'
  }

  const isTrendsListPage =
    get(props, 'location.pathname', '') === `${process.env.ANT_PREFIX}/trends`

  const isLargeUp =
    props.screenType === SCREEN_TYPE.LARGE ||
    props.screenType === SCREEN_TYPE.XL ||
    props.screenType === SCREEN_TYPE.XXL

  const isMedium = props.screenType === SCREEN_TYPE.MEDIUM

  let size = ''
  if (props.screenType === 'small') {
    size = 'small'
  } else if (props.screenType === 'medium') {
    size = 'medium'
  } else {
    size = 'large'
  }

  const imageUrl = get(props, 'content.image.main', undefined)

  let imageWidth = get(config, `${cardName}.image.${size}.width`, 600)
  let imageHeight = get(config, `${cardName}.image.${size}.height`, '')

  // higher res for scalability
  imageWidth = Math.round(imageWidth * 1.5)
  imageHeight = Math.round(imageHeight * 1.5)

  // wide images for trendList grid
  let wideImageWidth = ''
  let wideImageHeight = ''
  if (props.variant === 'Trend' && isTrendsListPage) {
    if (isLargeUp) {
      if (
        props.position === 4 ||
        props.position === 13 ||
        props.position === 18
      ) {
        wideImageWidth = 640
        wideImageHeight = 330
      }
    }
    if (isMedium) {
      if (props.position === 3 || props.position === 15) {
        wideImageWidth = 640
        wideImageHeight = 340
      }
    }
  }

  const withExtraInfo = get(config, `articleCard.withExtraInfo`, false)
  const { showTaggedCategory, showSponsoredBy } = config.article
  let subText = ''

  const categoryName = get(props, 'content.theme', '')

  const isSponsored = get(props, 'content.sponsor', false)

  if (showSponsoredBy && isSponsored) {
    subText = `Sponsored`
  } else if (showTaggedCategory && categoryName) {
    subText = categoryName
  }

  if (categoryName === false && subText !== `Sponsored`) subText = 'FASHION'

  const {
    pageName = '',
    gaContext = '',
    className,
    activeClass,
    position,
    content
  } = props

  const { uuid, title, slug } = content

  let type = content.type
  if (type === 'insta') {
    type = 'instalook'
  }

  const cardGASettings = gaSettings.article.ContentCard
  const labelProps = {
    ...{ uuid },
    ...{ type },
    ...{ position }
  }

  const label = contentCardGALabel(labelProps)
  const additionalProps = createContentCardGAProps(labelProps)

  const gaEvent = {
    category: cardGASettings.category.replace(
      '$pageName',
      gaContext || pageName
    ),
    action: cardGASettings.action,
    label: label.join(' - '),
  }

  const cardClasses = `${className} ${activeClass} content-card`
  const cardTo = path(type, { uuid, slug })

  return cardName === 'instalookCard' ? (
    <Link
      data-cy="content-card"
      className={cardClasses}
      to={cardTo}
      onClick={() => {
        sendTrackingEvent({ amplitude: {}, gaEvent, additionalProps })
        if (props.pageName === 'nav-panel-content') {
          props.closeNavPanel()
        }
      }}
      alt="content-card image"
      aria-label="content-card"
    >
      <div className="content-card__image-wrapper">
        <LazyLoadImage
          image={imageUrl}
          className="content-card__image"
          width={imageWidth}
          height={imageHeight}
          crop={'fill'}
          alt={title.long}
        />
      </div>
      <div className="insta-card__info-wrapper">
        <div className="insta-card__bar">
          <Icon name="insta-heart" />
          <Icon name="insta-comment" />
        </div>
        <div className="content-card__info">
          <h2 className="content-card__title">
            {cutTitleToLimit(sanitize(title.long), 55)}
          </h2>
        </div>
        {props.children}
      </div>
    </Link>
  ) : (
    <Link
      onClick={() => sendTrackingEvent({ amplitude: {}, gaEvent, additionalProps })}
      className={cardClasses}
      to={cardTo}
      data-cy="content-card"
      alt="content-card image"
      aria-label="content-card"
    >
      <div className="content-card__image-wrapper">
        <LazyLoadImage
          image={imageUrl}
          className="content-card__image"
          width={wideImageWidth || imageWidth}
          height={wideImageHeight || imageHeight}
          crop={'fill'}
          alt={title.long}
        />
      </div>
      <div className="content-card__info">
        {withExtraInfo && subText && (
          <h2 className="content-card__category">{subText}</h2>
        )}
        <h2 className="content-card__title">
          {cutTitleToLimit(sanitize(title.long), 55)}
        </h2>
      </div>
      {props.children}
    </Link>
  )
}

ContentCard.propTypes = {
  activeClass: PropTypes.string,
  children: PropTypes.any,
  className: PropTypes.any,
  closeNavPanel: PropTypes.func,
  content: PropTypes.shape({
    slug: PropTypes.any,
    title: PropTypes.shape({
      long: PropTypes.any
    }),
    type: PropTypes.string,
    uuid: PropTypes.any
  }),
  gaContext: PropTypes.string,
  image: PropTypes.string,
  pageName: PropTypes.string,
  position: PropTypes.number,
  screenType: PropTypes.string,
  variant: PropTypes.string
}

ContentCard.defaultProps = {
  activeClass: '',
  image: 'http://via.placehold.it/300x200'
}

export default ContentCard
