import { css } from '@emotion/core'
import { smallOnly, mediumUp } from 'Utils'

export default ({
  theme,
  theme: {
    components: { priceFilter },
    mixins,
    spacing
  }
}) => {
  return css`
    @media ${smallOnly} {
      display: inline-block;
      justify-content: left;

      a,
      .disabled {
        ${mixins.filterPanelListItem(theme)};
        padding-left: ${spacing.vertical.xsmall};
        border-bottom: none;
        width: 100%;
      }

      .selected {
        background-color: black;
        color: white;
      }
    }

    @media ${mediumUp} {
      a {
        ${mixins.filterLinks(theme)}
      }
      .selected {
        background-color: black;
        color: white;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .close-icon {
        ${mixins.closeIcon(theme)}
      }
    }
  `
}
