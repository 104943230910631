import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Link, Icon } from 'Blocks'
import { path } from 'Utils'
import { PAGE_CONTEXT as p } from 'Constants'
import {
  setActiveTopLevelCategory,
  setCurrentPath,
  closeNavPanel
} from 'Actions'

const FavouritesLink = props => {
  function clearActiveTopLevelCategory() {
    props.setActiveTopLevelCategory({ name: '', uuid: '' })
    props.setCurrentPath([])
    props.closeNavPanel()
  }
  return (
    <Link
      to={path(p.FAVOURITES)}
      className="favourites-link"
      onClick={() => clearActiveTopLevelCategory()}
    >
      <Icon name="favorite_border" />
    </Link>
  )
}

const mapDispatchToProps = dispatch => ({
  closeNavPanel: bindActionCreators(closeNavPanel, dispatch),
  setActiveTopLevelCategory: bindActionCreators(
    setActiveTopLevelCategory,
    dispatch
  ),
  setCurrentPath: bindActionCreators(setCurrentPath, dispatch)
})

FavouritesLink.propTypes = {
  setActiveTopLevelCategory: PropTypes.func,
  setCurrentPath: PropTypes.func,
  closeNavPanel: PropTypes.func
}

export default connect(null, mapDispatchToProps)(FavouritesLink)
