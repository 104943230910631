import { css } from '@emotion/core'
import theme from 'Theme'
const { spacing, mixins, typography } = theme

export default () => css`
  &.main-container {
    ${mixins.mainContainerTopPadding(theme)}
    min-height: 100vh;
    position: relative;
    padding-bottom: ${spacing.vertical.medium};
  }

  .no-match__info {
    width: 95%;
    max-width: 450px;
    margin: 0 auto;
    text-align: center;
    h2 {
      font-size: ${typography.sizes.large};
      font-weight: bold;
    }

    h2,
    p {
      margin-bottom: ${spacing.vertical.medium};
    }

    &.error__page {
      h2 {
        letter-spacing: 0.5px;
      }
      p {
        letter-spacing: 0.5px;
      }
    }
  }
`
