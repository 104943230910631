import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import { contentPropTypes } from 'Types'
import { ContentListSimple } from 'Blocks'
import config from 'Config'
import { PAGE_CONTEXT as p } from 'Constants'

const ListArticles = props => {
  const groupContents = (contents, section) => {
    if (Array.isArray(contents) && contents.length > 0) {
      const hasMinimumFourItems = contents.length > 3
      const firstFourItems = contents.slice(0, 4)
      const remainingItems = contents.slice(4, 12)

      if (section === 'first') {
        return hasMinimumFourItems ? firstFourItems : []
      }

      if (section === 'second') {
        return hasMinimumFourItems ? remainingItems : contents
      }

      return contents
    } else {
      return []
    }
  }

  const contents = props.data.contents.contents
  const contentsGroup = groupContents(contents, props.section)
  const sectionClass = props.section === 'first' ? 'first' : 'second'
  return (
    <Fragment>
      <ContentListSimple
        className={`content-list__articles ${props.className} ${sectionClass}`}
        contentType="article"
        contents={contentsGroup}
        title={config.articles.homeArticles.title}
        linkLabel={config.articles.homeArticles.showMore}
        pageContext={
          props.section === 'second' ? 'smallArticle' : p.HOME_ARTICLES
        }
        pageName={props.section === 'second' ? 'homeSmall' : p.HOME}
      />
    </Fragment>
  )
}

ListArticles.propTypes = {
  className: PropTypes.any,
  data: PropTypes.shape({
    contents: PropTypes.shape({
      contents: PropTypes.arrayOf(PropTypes.shape({ ...contentPropTypes }))
    })
  }),
  section: PropTypes.string
}

export default ListArticles
