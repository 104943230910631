import PropTypes from 'prop-types'
import React from 'react'
import { path, resolveContentCardSkins } from 'Utils'
import theme from 'Theme'
import get from 'lodash/get'
import { PAGE_CONTEXT as p } from 'Constants'
import { ContentCard, Link, Grid } from 'Blocks'

const {
  layout: { itemsPerRow }
} = theme

const ContentListSimple = ({
  isLoading = false,
  children,
  screenType,
  contents = [],
  className,
  title,
  linkLabel,
  limit,
  contentType = 'article',
  pageContext,
  pageName,
  context,
  showHeader = true
}) => {
  const browseLink = path(p.PRODUCT_LIST, {
    uuid: process.env.ANT_BROWSE_ID,
    subCategory: 'browse'
  })
  const to =
    contentType === 'category' ? browseLink : path(`${contentType}List`)
  const rules = get(itemsPerRow, [context], false)

  const limitFromLayout = rules
    ? get(rules, [screenType], rules.fallback)
    : contents.length
  const contentsLimit = limit || limitFromLayout

  const renderGridItems = () => {
    return (
      children ||
      contents.slice(0, contentsLimit).map((content, index) => {
        return (
          <ContentCard
            key={`content-list-simple-${content.uuid}`}
            position={index + 1}
            useCategoryName={true}
            variant={resolveContentCardSkins(pageContext)}
            content={content}
            pageContext={pageContext}
          />
        )
      })
    )
  }

  return isLoading ? (
    <div className={`content-list content-list__loading ${className} `}>
      <div className="content-list__title"></div>
      <Grid className="content-list__grid">
        <div className="content-list__grid-item" />
        <div className="content-list__grid-item" />
        <div className="content-list__grid-item" />
        <div className="content-list__grid-item" />
      </Grid>
    </div>
  ) : (
    <div className={`content-list ${className} `}>
      {showHeader && (
        <div className="content-list__header">
          <h2 className="content-list__title">{title}</h2>
          <Link
            data-cy={`${contentType}s_link`}
            className="content-list__cta"
            to={to}
          >
            {linkLabel}
          </Link>
        </div>
      )}
      <Grid pageName={pageName} className="content-list__grid">
        {renderGridItems()}
      </Grid>
    </div>
  )
}

ContentListSimple.propTypes = {
  children: PropTypes.node,
  className: PropTypes.any,
  contentType: PropTypes.string,
  contents: PropTypes.array,
  context: PropTypes.any,
  isLoading: PropTypes.bool,
  limit: PropTypes.any,
  linkLabel: PropTypes.any,
  pageContext: PropTypes.any,
  pageName: PropTypes.any,
  screenType: PropTypes.any,
  showHeader: PropTypes.bool,
  title: PropTypes.any
}

export default ContentListSimple
