import { getPage } from 'Utils'
import config from 'Config'

// TODO getInstalooksQueryVariables, getArticlesQueryVariables, getTrendsQueryVariables into one in src/queries.
export const getInstalooksQueryVariables = (queryString, size) => {
  return {
    arguments: {
      type: 'insta',
      state: 'PUBLISHED'
    },
    pagination: {
      page: getPage(queryString),
      size: size || config.pagination.instalookList
    }
  }
}
