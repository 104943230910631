import PropTypes from 'prop-types'
import React from 'react'

const NavPanelOverlay = props => {
  return props.isActive ? (
    <div
      onMouseOver={e => {
        props.toggleNavPanel()
        e.persist()
      }}
      className={`${props.className} nav-panel-overlay`}
    ></div>
  ) : null
}

NavPanelOverlay.propTypes = {
  className: PropTypes.any,
  isActive: PropTypes.any,
  toggleNavPanel: PropTypes.func
}

export default NavPanelOverlay
