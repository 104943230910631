import plain from '../plain'
import merge from 'lodash/merge'

const theme = merge(plain, {
  components: {
    ...plain.components,
    articleTarget: {
      spacing: {
        small: `${plain.spacing.base_3}px`,
        large: `${plain.spacing.base_3}px`,
        paragraph: `${plain.spacing.base_3}px`
      }
    }
  }
})

export default theme
