import { getPage } from 'Utils'
import config from 'Config'

export const getTrendsQueryVariables = (queryString, size) => {
  return {
    arguments: {
      type: 'trend',
      state: 'PUBLISHED'
    },
    pagination: {
      page: queryString ? getPage(queryString) : 1,
      size: size || config.pagination.articleList
    }
  }
}
